import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'largeContainer',
  'subContainer',
  'divider',
  'contentContainer',
  'textAndLinkContainer',
  'link',
  'imageContainer',
  'sticker',
]);

export const largeImageAndTextTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    largeContainer: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: { base: 'column', lg: 'row' },
      position: 'relative',
      maxHeight: ['635px', '850px'],
    },
    subContainer: {
      width: { lg: '50%' },
      flexGrow: { lg: 1 },
      color: 'white',
      backgroundColor: 'offGrey.500',
      padding: { base: '1.25rem 0', lg: '1.875rem 0' },
      zIndex: 1,
    },
    divider: {
      height: '1.25rem',
      borderTopLeftRadius: 'full',
      borderWidth: '1px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: 'white',
    },
    contentContainer: {
      padding: { base: '0 0.75rem', lg: '0 0.75rem 0 1.875rem' },
      display: 'flex',
      minHeight: '15vh',
    },
    textAndLinkContainer: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      a: {
        p: {
          letterSpacing: '-0.01em',
        },
      },
    },
    textContainer: {
      marginBottom: '0',
      '.sc-rich-text': {
        padding: { base: '0', lg: '0 12px' },
        p: {
          marginBottom: '1.25rem',
        },
      },
    },
    link: {
      color: 'white',
      padding: '0.5rem 0.75rem',
      borderWidth: '1px',
      borderColor: 'white',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      marginTop: '1.5rem',
      fontSize: '14px',
    },
    imageContainer: {
      width: { lg: '50%' },
      flexGrow: 1,
    },
    sticker: {
      position: 'absolute',
      bottom: '-50px',
      left: '20px',
      height: { base: '140px', md: '190px', lg: '250px' },
      width: { base: '140px', md: '190px', lg: '250px' },
    },
  },
});
