import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { breadcrumbTheme as base } from 'themes/base/components/breadcrumb';

const helpers = createMultiStyleConfigHelpers(['container', 'divider', 'breadcrumb']);

export const breadcrumbTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    ...base.baseStyle,
    container: {
      ...base.baseStyle?.container,
      padding: { base: '10px 0 16px 0.5rem', md: '8px 1.875rem 20px 1.875rem' },
    },
    scrollFade: {
      ...base.baseStyle?.scrollFade,
    },
    breadcrumbListContainer: {
      ...base.baseStyle?.breadcrumbListContainer,
      padding: { base: '0 10px 0 22px', lg: '0 0 0 22px' },
    },
    divider: {
      ...base.baseStyle?.divider,
      top: { base: '1rem', md: '1rem' },
    },
  },
});
