import { defineStyleConfig } from '@chakra-ui/react';

export const textParagraphTheme = defineStyleConfig({
  baseStyle: {
    // maxWidth: ['20rem', '40rem'],
    //moved in global.ts
    // '&.rte-left-margin': {
    //   paddingLeft: ['20px', '32px'],
    // },
    padding: { base: '0 12px', lg: '0' },
    ol: {
      marginBottom: '1rem',
    },
    ul: {
      marginBottom: '1rem',
    },
  },
});
