import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['footer', 'item']);

export const footerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    footerContainer: {
      width: '100%',
    },
    footer: {
      background: 'white',
    },
    item: {
      color: 'white',
      '& .sc-rich-text': {
        p: {
          marginBottom: '8px',
        },
        a: {
          textDecoration: 'none',
          _hover: {
            textDecoration: 'underline',
            textShadow: '0 0 0 #fff',
          },
        },
        maxWidth: '1440px',
        margin: '0 auto',
      },
    },
  },
});
