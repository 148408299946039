export const fonts = {
  body: 'Inter, system-ui, sans-serif',
  heading: 'Anton, system-ui, sans-serif',
};

export const textStyles = {
  h1: {
    fontSize: {
      base: '40px',
      sm: '60px',
      md: '90px',
    },
    marginLeft: {
      sm: '-0.07rem',
      md: '-0.13rem',
    },
    fontFamily: 'heading',
  },
  h2: {
    fontSize: {
      base: '24px',
      sm: '30px',
      md: '36px',
    },
    fontFamily: 'heading',
  },
  h3: {
    fontSize: {
      base: '20px',
      sm: '24px',
      md: '28px',
    },
  },
  h4: {
    fontSize: {
      base: '20px',
      sm: '22px',
      md: '24px',
    },
  },
  h5: {
    fontSize: {
      base: '16px',
      sm: '18px',
    },
  },
  p: {
    fontSize: {
      base: '16px',
      sm: '18px',
    },
    fontWeight: 'normal',
    marginBottom: '1rem',
  },
  a: {
    transition: 'text-shadow 0.3s ease-in-out',
    textDecoration: 'underline',
    _hover: {
      textShadow: '0 0 0 #000',
    },
  },
  ul: {
    marginLeft: '1rem',
  },
};
