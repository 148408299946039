import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'textContainer', 'tag', 'title', 'imageContainer']);

export const slider2ItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      display: 'flex',
      color: 'white',
      width: { base: '16.75rem', lg: '22rem', xl: '26.5rem' },
      maxWidth: '90%',
      marginRight: { base: '1rem', lg: '2rem' },
      borderRadius: { base: '130px', lg: '210px', xl: '300px' },
      'a:hover': { textDecoration: 'none' },
      WebkitTapHighlightColor: 'transparent',
      cursor: 'pointer',
    },
    textContainer: { marginTop: '1rem' },
    tag: {
      textTransform: 'uppercase',
      color: 'white',
      fontSize: { base: '10px', md: '12px' },
      marginBottom: { base: '0.25rem', lg: '0' },
      p: {
        letterSpacing: '0.15em',
      },
    },
    title: {
      color: 'white',
      fontFamily: 'body',
    },
    imageContainer: {
      //for video must use height, minHeight not enough
      height: { base: '20.125rem', lg: '26.5rem', xl: '32.75rem' },
      //for nextImage
      minHeight: { base: '20.125rem', lg: '26.5rem', xl: '32.75rem' },
      width: { base: '16.75rem', lg: '22rem', xl: '26.5rem' },
      position: 'relative',
      overflow: 'hidden',
      borderRadius: { base: '130px', lg: '210px', xl: '300px' },
      borderBottomLeftRadius: { base: 0, lg: 0, xl: 0 },
    },
  },
});
