import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  w: '100%',
  mx: 'auto',
  maxW: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
  px: '4',
});

export const containerTheme = defineStyleConfig({
  baseStyle,
});
