import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'sticker', 'image']);

export const tickerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      // lineHeight: { base: '100px', lg: '325px' },
      '.marquee-container': {
        '.overlay': {
          '--gradient-width': { base: '64px!important', lg: '128px!important' },
        },
      },
    },
  },
});
