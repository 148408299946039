import { defineStyleConfig } from '@chakra-ui/styled-system';

export const hostelEventCalendarTheme = defineStyleConfig({
  baseStyle: {
    button: {
      height: '45px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      lineHeight: 1,
      backgroundColor: 'gray',
      border: '1px solid gray',
      fontSize: '14px',
      marginTop: '10px',
      minWidth: 'fit-content',
      borderRadius: '0px 50px 50px 50px',
      padding: '14px',
      color: 'white',
      cursor: 'pointer',
      width: '100%',
    },
    copyLink: {
      color: 'blue',
      cursor: 'pointer',
    },
    deleteLink: {
      color: '#BF0000',
      cursor: 'pointer',
    },
    textButtons: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      fontSize: '12px',
      marginBottom: '4px',
    },
    timeControl: {
      border: '1px solid #4E5356',
      borderRadius: '6px',
      padding: '0 0.5rem',
      label: {
        position: 'relative',
        top: '-1px',
        fontSize: '12px',
        marginBottom: 0,
      },
    },
    timeBox: {
      width: '100%',
      position: 'relative',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      svg: {
        position: 'absolute',
        top: '4px',
        transform: 'translateY(-50%)',
        right: '0',
        cursor: 'pointer',
      },
      input: {
        width: '100%',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        _focusVisible: {
          outline: 'none',
        },
      },
      '.react-datepicker__aria-live': {
        display: 'none',
      },
      '.react-datepicker-popper': {
        backgroundColor: 'white',
        zIndex: 10,
        padding: '0 5px',
        width: '100%',
        height: '400px',
        overflowY: 'scroll',
      },
      '.react-datepicker__header--time': {
        display: 'none',
      },
      '.react-datepicker__time-list': {
        listStyle: 'none',
        cursor: 'pointer',
      },
      '.react-datepicker__time-list-item--disabled': {
        display: 'none',
      },
    },
    dateControl: {
      border: '1px solid #4E5356',
      borderRadius: '6px',
      cursor: 'pointer',
      '.react-datepicker__aria-live': {
        display: 'none',
      },
      label: {
        position: 'relative',
        top: '-1px',
        fontSize: '12px',
        marginBottom: 0,
        marginLeft: '0.5rem',
      },
      input: {
        width: '100%',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        marginLeft: '0.5rem',
        _focusVisible: {
          outline: 'none',
        },
      },
      '.react-datepicker-popper': {
        zIndex: 10,
        '.react-datepicker__header': {
          backgroundColor: 'white',
          border: '1px solid gray',
        },
        '.react-datepicker__month': {
          backgroundColor: 'white',
          border: '1px solid gray',
          '.react-datepicker__day--disabled': {
            color: '#ccc',
            cursor: 'not-allowed',
          },
        },
        '.react-datepicker__current-month': {
          textAlign: 'center',
        },
        '.react-datepicker__navigation--previous': {
          width: '32px',
          height: '32px',
          position: 'absolute',
        },
        '.react-datepicker__navigation--next': {
          width: '32px',
          height: '32px',
          position: 'absolute',
        },
      },
    },
    recurringBox: {
      width: '50%',
    },
    recurringCheckbox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginY: '1.25rem',
      '.chakra-form__label ': {
        marginBottom: 0,
        marginLeft: '0.5rem',
      },
    },
    daysGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '1rem',
      width: '100%',
      maxWidth: '100%',
      gap: '1rem',
      '.chakra-checkbox__label': {
        fontSize: '12px',
      },
    },
    colorSpan: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      position: 'relative',
      top: '-38px',
      right: '30px',
    },
    imageBox: {
      margin: '8px auto',
      width: '280px',
      height: '210px',
      position: 'relative',
      img: {
        borderRadius: '6px',
        // width: '100%',
        // height: '100%',
        // objectFit: 'cover',
      },
    },
    hostelCalendarContainer: {
      backgroundColor: '#FFFFF6',
      width: '70%',
      height: 'fit-content',
      borderRadius: '10px 10px 0px 0px',
    },
    dayContainerHeader: {
      borderRadius: '10px 10px 0px 0px',
      border: '1px solid #4E5356',
      borderBottom: 'none',
    },
    eventContainer: {
      width: '14.3%',
      padding: '10px 5px',
      borderTop: '1px solid #4E5356',
      borderLeft: '1px solid #4E5356',
      _last: {
        borderRight: '1px solid #4E5356',
      },
    },
    dayContainer: {
      width: '14.3%',
      padding: '10px 5px',
    },
    dayHeader: {
      textTransform: 'capitalize',
      textAlign: 'center',
    },
    eventDayContainer: {
      flexDirection: 'row',
      minHeight: '14rem',
      _first: {
        borderRadius: '10px 10px 0px 0px',
      },
      _last: {
        borderBottom: '1px solid #4E5356',
      },
    },
    eventContainerDate: {
      fontWeight: 'bold',
      marginLeft: 'auto',
      fontSize: '10px',
      borderRadius: '8px',
      width: 'fit-content',
      padding: '1px 5px',
      marginBottom: '16px',
      '&.eventContainerDateHighlighted': {
        backgroundColor: '#ECFF18',
      },
    },
    calendarEvent: {
      borderRadius: '8px',
      marginBottom: '6px',
      fontSize: '10px',
      fontWeight: 'bold',
      height: '23px',
      overflow: 'hidden',
      _hover: {
        cursor: 'pointer',
      },
    },
  },
});
