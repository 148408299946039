import { defineStyleConfig } from '@chakra-ui/styled-system';

export const hostelEventCalendarTheme = defineStyleConfig({
  baseStyle: {
    button: {
      height: '45px',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      lineHeight: 1,
      backgroundColor: 'gray',
      border: '1px solid gray',
      fontSize: '14px',
      marginTop: '10px',
      minWidth: 'fit-content',
      borderRadius: '0px 50px 50px 50px',
      padding: '14px',
      color: 'white',
      cursor: 'pointer',
      width: '100%',
    },
    copyLink: {
      color: 'blue',
      cursor: 'pointer',
    },
    deleteLink: {
      color: '#BF0000',
      cursor: 'pointer',
    },
    timeBox: {
      border: '1px solid gray',
      width: '100%',
      position: 'relative',
      zIndex: 2,
      input: {
        width: '100%',
        cursor: 'pointer',
      },
      '.react-datepicker-popper': {
        backgroundColor: 'lightgray',
        zIndex: 10,
        padding: '0 5px',
        width: '100%',
      },
      '.react-datepicker__header--time': {
        display: 'none',
      },
      '.react-datepicker__time-list': {
        listStyle: 'none',
        cursor: 'pointer',
      },
      '.react-datepicker__time-list-item--disabled': {
        display: 'none',
      },
    },
    dateControl: {
      paddingBottom: 0,
      height: '55px',
      borderRadius: '5px',
      cursor: 'pointer',
      input: {
        width: '100%',
        border: '1px solid gray',
      },
      '.react-datepicker-popper': {
        zIndex: 10,
        '.react-datepicker__header': {
          backgroundColor: 'white',
          border: '1px solid gray',
        },
        '.react-datepicker__month': {
          backgroundColor: 'white',
          border: '1px solid gray',
          '.react-datepicker__day--disabled': {
            color: '#ccc',
            cursor: 'not-allowed',
          },
        },
        '.react-datepicker__current-month': {
          textAlign: 'center',
        },
        '.react-datepicker__navigation--previous': {
          width: '32px',
          height: '32px',
          position: 'absolute',
        },
        '.react-datepicker__navigation--next': {
          width: '32px',
          height: '32px',
          position: 'absolute',
        },
      },
    },
    recurringBox: {
      width: '50%',
    },
    recurringCheckbox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTopWidth: '2px',
      borderStyle: 'dashed',
      marginTop: '1.25rem',
      paddingTop: '1.25rem',
    },
    daysGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '1rem',
      width: '100%',
      maxWidth: '100%',
      gap: '1rem',
    },
    colorSpan: {
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      position: 'relative',
      top: '35px',
      right: '30px',
    },
    imageBox: {
      width: '100%',
      height: '8rem',
      position: 'relative',
      backgroundColor: 'gray',
      // img: {
      //   width: '100%',
      //   height: '100%',
      //   objectFit: 'cover',
      // },
    },
    hostelCalendarContainer: {
      backgroundColor: '#FFFFF6',
      width: '70%',
      height: 'fit-content',
      borderRadius: '10px 10px 0px 0px',
    },
    dayContainerHeader: {
      borderRadius: '10px 10px 0px 0px',
      border: '1px solid #4E5356',
      borderBottom: 'none',
    },
    eventContainer: {
      width: '14.3%',
      padding: '10px 5px',
      borderTop: '1px solid #4E5356',
      borderLeft: '1px solid #4E5356',
      _last: {
        borderRight: '1px solid #4E5356',
      },
    },
    dayContainer: {
      width: '14.3%',
      padding: '10px 5px',
    },
    dayHeader: {
      textTransform: 'capitalize',
      textAlign: 'center',
    },
    eventDayContainer: {
      flexDirection: 'row',
      minHeight: '14rem',
      _first: {
        borderRadius: '10px 10px 0px 0px',
      },
      _last: {
        borderBottom: '1px solid #4E5356',
      },
    },
    eventContainerDate: {
      fontWeight: 'bold',
      marginLeft: 'auto',
      fontSize: '10px',
      border: '1px solid #4E5356',
      borderRadius: '50%',
      width: 'fit-content',
      padding: '7px 1px',
      marginBottom: '5px',
    },
    calendarEvent: {
      borderRadius: '8px',
      marginBottom: '6px',
      padding: '5px',
      fontSize: '10px',
      fontWeight: 'bold',
      height: '23px',
      overflow: 'hidden',
      _hover: {
        cursor: 'pointer',
      },
    },
  },
});
