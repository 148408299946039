import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const PriceTagTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      color: '#4C604C',
    },
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    textLabel: {
      fontSize: { base: '12px', md: '16px' },
    },
    priceLabel: {
      fontWeight: 'bold',
      fontSize: { base: '24px', md: '32px' },
    },
    currencyLabel: {
      marginRight: '4px',
      fontSize: { base: '10px', md: '13px' },
    },
  },
});
