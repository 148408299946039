import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['solid', 'outline', 'text', 'revertText']);

export const CTAButtonTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    solid: {
      position: 'relative',
      backgroundColor: 'black',
      padding: '12px 20px',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      fontWeight: 'semibold',
      overflow: 'hidden',
      _after: {
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'black',
        opacity: 0,
        transition: '0.25s linear',
        zIndex: 0,
      },
      '&.rte-left-margin': {
        marginLeft: ['12px', '32px'],
      },
      _hover: {
        _after: {
          opacity: 0.1,
          transition: '0.25s linear',
        },
        '.text': {
          transform: 'translateY(-110%)',
          transition: '0.25s linear',
        },
        '.revert-text': {
          transform: 'translateY(-100%)',
          transition: '0.25s linear',
        },
      },
      letterSpacing: '-0.01em',
    },
    outline: {
      position: 'relative',
      backgroundColor: 'transparent',
      padding: '12px 20px',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      fontWeight: 'semibold',
      borderWidth: '1px',
      borderColor: 'black',
      overflow: 'hidden',
      '&.rte-left-margin': {
        marginLeft: ['12px', '32px'],
      },
      _hover: {
        filter: 'brightness(90%)',
        '.text': {
          transform: 'translateY(-110%)',
          transition: '0.25s linear',
        },
        '.revert-text': {
          transform: 'translateY(-100%)',
          transition: '0.25s linear',
        },
      },
      letterSpacing: '-0.01em',
    },
    text: {
      color: 'white',
      transition: '0.25s linear',
      zIndex: 1,
      lineHeight: '16px',
      fontSize: '14px',
    },
    revertText: {
      position: 'absolute',
      width: '100%',
      color: 'white',
      transition: '0.25s ease-in-out',
      zIndex: 1,
      lineHeight: '1',
      fontSize: '14px',
    },
    small: {
      fontWeight: '600!important',
      fontSize: '14px',
      padding: '12px 20px!important',
      maxWidth: 'fit-content!important',
    },
    large: {
      fontWeight: '600!important',
      padding: '16px 32px!important',
      borderRadius: '0px 50px 50px 50px!important',
      width: ['351px', '380px'],
      whiteSpace: 'nowrap',
    },
    category: {
      fontWeight: '400!important',
      fontSize: '14px',
      padding: '8px 18px!important',
      maxWidth: 'fit-content!important',
    },
  },
});
