import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'search']);

export const faqTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: '#4C604C',
      position: 'relative',
      label: {
        left: '2rem !important',
      },
    },
    search: {
      input: {
        border: '1px solid transparent',
        paddingLeft: '10px',
        _hover: { borderColor: 'transparent' },
        _focus: { borderColor: 'transparent', boxShadow: 'none' },
        _focusVisible: { borderColor: 'transparent', boxShadow: 'none' },
      },
      label: {
        color: 'unset!important',
        letterSpacing: '-0.03em',
      },
      _after: {
        display: 'block',
        borderBottomLeftRadius: 16,
        borderBottom: '1px solid',
        borderLeft: '1px solid',
        borderColor: 'inherit',
        content: '""',
        w: '100%',
        h: '16px',
        position: 'relative',
        left: 0,
        bottom: '1.25rem',
        mb: 8,
      },
    },
  },
});
