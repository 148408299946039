import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'title',
  'imageContainer',
  'iconContainer',
  'icon',
  'bottomOverlay',
]);

export const eventCalendarItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      height: { base: '13.125rem', md: '18.375rem' },
      minWidth: { base: '8.75rem', md: '12rem' },
      maxWidth: { base: '8.75rem', md: '12rem' },
      marginRight: { base: '1rem', md: '2rem' },
      WebkitTapHighlightColor: 'transparent',
    },
    cardFront: {
      borderRadius: '20px',
      borderTopLeftRadius: 0,
      overflow: 'hidden',
      '.zoom-image': { transition: 'all 0.5s ease-in-out 0s' },
      _hover: {
        '.zoom-image': { transition: 'all 0.5s ease-in-out 0s', transform: 'scale(1.05)' },
      },
      letterSpacing: '-0.03em',
    },
    imageContainer: {
      height: { base: '6.875rem', md: '9.625rem' },
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      borderTopLeftRadius: 0,
    },
    contentContainer: {
      height: { base: '6.25rem', md: '8.75rem' },
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: { base: '0.563rem 0.625rem', md: '0.75rem 0.938rem' },
      textAlign: 'left',
    },
    location: {
      lineHeight: '110%',
      fontSize: { base: '12px', md: '14px' },
      marginBottom: '0.125rem',
    },
    time: {
      fontWeight: 'bold',
      fontSize: { base: '12px', md: '14px' },
    },
    title: {
      fontSize: { base: '16px', md: '20px' },
      marginTop: 'auto',
    },
    cardBack: {
      borderRadius: '20px',
      borderTopRightRadius: 0,
      overflow: 'hidden',
      padding: { base: '0.563rem 0.625rem', md: '0.75rem 0.938rem' },
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      letterSpacing: '-0.03em',
    },
    description: {
      fontSize: { base: '16px', md: '20px' },
      marginY: 'auto',
      lineHeight: '120%',
    },
    link: {
      fontSize: { base: '16px', md: '20px' },
      display: 'flex',
      alignItems: 'center',
      lineHeight: '110%',
    },
  },
});
