import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'divider', 'breadcrumb']);

export const breadcrumbTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      padding: { base: '16px 0 32px 0.5rem', md: '16px 1.875rem 20px 1.875rem' },
      width: '100vw',
      maxWidth: '100%',
      marginRight: 0,
      overflow: 'hidden',
    },
    breadcrumbListContainer: {
      display: 'flex',
      width: '100%',
      padding: '0 0 0 18px',
      overflow: 'auto',
      '::-webkit-scrollbar': { display: 'none' }, // hide scrollbar (Chrome, Safari, Opera)
      scrollbarWidth: 'none', // hide scrollbar (Firefox)
      msOverflowStyle: 'none', // hide scrollbar (IE, Edge)
    },
    scrollFade: {
      position: 'absolute',
      left: 0,
      height: '45%',
      width: '3rem',
      pointerEvents: 'none',
    },
    divider: {
      position: 'absolute',
      top: { base: '1.75rem', md: '1.5rem' },
      left: { base: '12px', md: '30px' },
      width: { base: 'calc(100% - 1.5rem)', md: 'calc(100% - 3.75rem)' },
      height: '1.25rem',
      borderBottomLeftRadius: 'full',
      borderWidth: '1px',
      borderTopWidth: 0,
      borderRightWidth: 0,
      pointerEvents: 'none',
    },
    breadcrumb: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
      minWidth: 'fit-content',
      fontSize: { base: '10px', md: '12px' },
      a: {
        letterSpacing: { base: '-0.03em', lg: 'unset' },
      },
    },
    breadcrumbLink: {
      marginRight: '8px',
      fontSize: { base: '10px', md: '12px' },
    },
  },
});
