import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'row']);

export const HostelContainerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    row: {
      //disable so that stickers can show above other rows
      // overflow: 'hidden',
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
});
