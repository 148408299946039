import { defineStyleConfig } from '@chakra-ui/react';

/**
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/heading.ts
 */
export const headlineTheme = defineStyleConfig({
  baseStyle: {
    container: {
      color: '#4C604C',
      display: 'flex',
      wordBreak: 'break-word',
      paddingBottom: { base: '1.25rem', lg: '1.5rem' },
      paddingX: { base: '12px', lg: 0 },
    },
    bigText: { lineHeight: '100%' },
    smallText: { fontSize: { base: '20px', lg: '24px' } },
  },
});
