import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'anchorsContainer', 'anchorItem', 'cardContainer']);

export const productCardListTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      paddingInlineStart: '0px!important',
      paddingInlineEnd: '0px!important',
    },
    anchorsContainer: {
      position: 'sticky',
      top: 0,
      gap: '0.5rem',
      padding: '0.5rem',
      paddingBottom: '1.25rem',
      overflow: 'auto',
      '::-webkit-scrollbar': { display: 'none' }, // hide scrollbar (Chrome, Safari, Opera)
      scrollbarWidth: 'none', // hide scrollbar (Firefox)
      msOverflowStyle: 'none', // hide scrollbar (IE, Edge)
      WebkitTapHighlightColor: 'transparent',
      zIndex: 2,
    },
    anchorItem: {
      minWidth: 'fit-content',
      borderWidth: '1px',
      borderRadius: 'full',
      borderColor: 'black',
      color: 'black',
      padding: '0.5rem 1rem',
      fontSize: '14px',
      'a:hover': { textDecoration: 'none' },
    },
    cardContainer: {
      display: 'flex',
      // alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: { base: '1.25rem', lg: '2rem' },
      padding: { base: '0px 12px', lg: '30px' },
    },
  },
});
