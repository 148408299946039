import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const slider2Theme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: '100vw',
      minHeight: { base: '25rem', lg: '30rem', xl: '37rem' },
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
      '.hiddenControls': {
        position: 'relative',
        '.image-slider2-button-next': {
          display: 'none',
        },
        '.image-slider2-button-prev': {
          display: 'none',
        },
      },
      '.sliderContainer': {
        // padding: { base: '2rem 0.75rem', lg: '2rem' },
        outline: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
        paddingBottom: { base: '0', lg: '1.25rem' },
      },
      '.swiper-scrollbar': {
        position: 'static',
        height: '1px',
        marginTop: { base: '1.125rem', lg: '2.5rem' },
        cursor: 'pointer',
        maxWidth: {
          base: 'calc(100% - 12px)',
          md: 'calc(100% - 24px)',
        },
        _before: {
          content: '" "',
          display: 'flex',
          width: '100%',
          height: '1px',
          alignSelf: 'center',
          marginBottom: '-1px',
          color: 'white',
        },
      },
      '.swiper-scrollbar-drag': {
        // marginTop: '-5px',
        height: '1px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'grab',
        // backgroundColor: 'white',
        _active: { cursor: 'grabbing' },
        _before: {
          content: '" "',
          display: 'flex',
          width: '100%',
          height: '1px',
          color: 'white',
        },
      },
    },
    arrowPrev: {
      position: 'absolute',
      top: 'calc(100% - 40px)',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
    arrowNext: {
      position: 'absolute',
      top: 'calc(100% - 40px)',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  },
});
