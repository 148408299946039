import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { slider2ItemTheme as baseSlider2ItemTheme } from 'themes/base/components/Slider2/Slider2Item';

const helpers = createMultiStyleConfigHelpers(['container', 'textContainer', 'tag', 'title', 'imageContainer']);

export const slider2ItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    ...baseSlider2ItemTheme.baseStyle,
    title: {
      fontFamily: 'heading',
    },
  },
});
