import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'footer',
  'item',
  'divider',
  'bottomDivider',
  'gridDivider',
  'border',
  'titles',
  'text',
  'creditCardLogo',
  'copyright',
]);

export const footerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    footerContainer: {
      width: '100%',
    },
    footer: {
      paddingX: ['0.75rem', '2rem'],
      paddingY: 6,
      p: { paddingBottom: '8px', fontSize: ['14px', '16px'] },
      a: {
        _hover: {
          textDecoration: 'underline',
        },
      },
      maxWidth: '1440px',
      margin: '0 auto',
    },
    divider: {
      height: '1.25rem',
      marginBottom: '-1rem',
      borderTopLeftRadius: 'full',
      borderWidth: '1px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: 'white',
    },
    footerSection: {
      div: {
        paddingLeft: { base: '0', md: '12px' },
      },
    },
    bottomDivider: {
      height: '1.25rem',
      marginTop: { base: '-0.25rem', md: 0 },
      marginBottom: '-0.75rem',
      borderBottomLeftRadius: 'full',
      borderWidth: '1px',
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderColor: 'white',
    },
    gridDivider: {
      height: '1px',
      backgroundColor: 'rgba(255, 255, 246, 0.2)',
    },
    item: {
      color: 'white',
      '& .sc-rich-text': {
        p: {
          marginBottom: '8px',
        },
        a: {
          textDecoration: 'none',
          _hover: {
            textDecoration: 'underline',
            textShadow: '0 0 0 #fff',
          },
        },
      },
    },
    contrySelected: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      color: 'white',
      cursor: 'pointer',

      p: {
        padding: '0',
      },

      '.text': {
        marginRight: '1rem',
        fontSize: '12px',
      },
    },
    socialsCountry: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: { base: 0, md: '-1rem' },
      width: { base: 'calc(100% - 0.5rem)', md: 'calc(50% - 1.75rem)' },
      alignItems: 'flex-end',
      justifyContent: 'flex-end',

      '.container': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',

        '.socialsContainer': {
          display: 'flex',

          '.imageContainer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '1rem',
            border: '1px solid white',
            borderRadius: '50%',
            width: '30px',
            height: '30px',

            a: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            },
          },
        },
      },
    },
    copyright: {
      width: 'fit-content',
      marginTop: { base: '5rem', md: '7rem' },
      marginLeft: 'auto',
      marginRight: ['auto', '0'],
      paddingBottom: 0,
      color: 'white',
      fontSize: '12px',
      opacity: '0.5',
      '.sc-rich-text': {
        marginBottom: { base: '-20px', md: '0' },
      },
    },
    iconContainer: {
      border: '1px white solid',
      borderRadius: '50%',
    },
  },
});
