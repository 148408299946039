import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const floating = definePartsStyle(() => {
  return {
    field: {
      border: '0',
      bg: 'transparent',
    },
    addon: {
      border: '1px solid',
      borderColor: 'inherit',
    },
  };
});

export const inputTheme = defineMultiStyleConfig({
  variants: { floating },
});
