import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'title', 'excerpt', 'readMore']);

export const searchResultTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      paddingTop: '1.5rem',
      paddingBottom: { base: '1rem', md: '1.5rem' },
      borderTopWidth: '2px',
      borderStyle: 'dashed',
      marginX: { base: '12px', lg: '0px' },
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: { base: '20px', md: '28px' },
      marginBottom: '1rem',
      _hover: {
        cursor: 'pointer',
      },
    },
    arrow: {
      borderWidth: '1px',
      borderColor: 'darkGrey.700',
      borderRadius: 'full',
      height: '30px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        line: {
          stroke: 'darkGrey.700',
        },
        path: {
          stroke: 'darkGrey.700',
        },
      },
    },
    excerpt: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0.25rem',
      noOfLines: { base: 6, md: 4, lg: 3 },
      _hover: {
        cursor: 'pointer',
      },
    },
    readMore: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      background:
        'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6083026960784313) 17%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)',
      paddingLeft: '5rem',
      fontWeight: 'bold',
      '-webkit-tap-highlight-color': 'transparent',
    },
  },
});
