import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'sticker', 'image']);

export const singleImageTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
    },
    sticker: {
      position: 'absolute',
      bottom: 2,
      right: 2,
    },
    image: {
      position: 'relative',
      height: 'inherit',
      width: 'inherit',
    },
  },
  sizes: {
    sm: {
      container: {
        height: 32,
        width: 32,
      },
      sticker: {
        h: 16,
        w: 16,
      },
    },
    md: {
      container: {
        height: 64,
        width: 64,
      },
      sticker: {
        h: 32,
        w: 32,
      },
    },
    lg: {
      container: {
        height: 96,
        width: 96,
      },
      sticker: {
        h: 48,
        w: 48,
      },
    },
    xl: {
      container: {
        width: '100vw',
        height: '100vw',
        maxWidth: '100%',
      },
      sticker: {
        h: 48,
        w: 48,
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
});
