import { base } from '../base';
import { colors } from './colors';
import { globalStyles } from './global';
import { fonts, textStyles } from './fonts';
import { mapsTheme } from './components/maps';
import { heroTheme } from './components/hero';
import { extendTheme } from '@chakra-ui/react';
import { radioTheme } from './components/radio';
import { modalTheme } from './components/Modal';
import { headerTheme } from './components/header';
import { buttonTheme } from './components/button';
import { footerTheme } from './components/footer';
import { headingTheme } from './components/heading';
import { poiIconTheme } from './components/poiIcon';
import { checkboxTheme } from './components/checkbox';
import { LinkListTheme } from './components/linkList';
import { headlineTheme } from './components/headline';
import { formTheme } from 'themes/base/components/form';
import { centerTextTheme } from './components/centerText';
import { breadcrumbTheme } from './components/breadcrumb';
import { slider1Theme } from './components/Slider1/Slider1';
import { singleImageTheme } from './components/singleImage';
import { imageGalleryTheme } from './components/imageGallery';
import { dividerTheme } from 'themes/base/components/divider';
import { textParagraphTheme } from './components/textParagraph';
import { containerTheme } from 'themes/base/components/container';
import { slider1ItemTheme } from './components/Slider1/Slider1Item';
import { largeImageAndTextTheme } from './components/largeImageAndText';
import { instagramSocialWallTheme } from './components/instagramSocialWall';
import { groupReservationFormTheme } from './components/groupReservationForm';
import { productCardGalleryTheme } from './components/ProductCardList/ProductCardGallery';
import { searchTheme } from './components/Search/search';
import { hostelEventCalendarTheme } from './components/HostelEventCalendar/HostelEventCalendar';

export const nexthouse: typeof base = extendTheme({
  ...base,

  fonts,
  textStyles,
  colors,
  components: {
    ...base.components,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Container: containerTheme,
    Divider: dividerTheme,
    Footer: footerTheme,
    Form: formTheme,
    Header: headerTheme,
    Heading: headingTheme,
    PoiIcon: poiIconTheme,
    Hero: heroTheme,
    Radio: radioTheme,
    LinkList: LinkListTheme,
    Headline: headlineTheme,
    CenterText: centerTextTheme,
    LargeImageAndText: largeImageAndTextTheme,
    TextParagraph: textParagraphTheme,
    ImageGallery: imageGalleryTheme,
    Slider1: slider1Theme,
    Slider1Item: slider1ItemTheme,
    SingleImage: singleImageTheme,
    Maps: mapsTheme,
    GroupReservationForm: groupReservationFormTheme,
    Modal: modalTheme,
    InstagramSocialWall: instagramSocialWallTheme,
    Breadcrumb: breadcrumbTheme,
    Search: searchTheme,
    ProductCardGallery: productCardGalleryTheme,
    HostelEventCalendar: hostelEventCalendarTheme,
  },

  styles: {
    global: globalStyles,
  },
});
