import { defineStyleConfig } from '@chakra-ui/react';

export const imageGalleryTheme = defineStyleConfig({
  baseStyle: {
    container: {
      aspectRatio: '1/1',
      height: 'fit-content',
      '.sticker': {
        position: 'absolute',
        top: { base: '-9.5%', md: '-9.5%' },
        left: { base: '70%', md: '5%' },
        width: { base: '110px', md: '200px' },
        height: { base: '110px', md: '200px' },
        userSelect: 'none',
      },
      '.priceTag': {
        position: 'absolute',
        top: { base: '-8.5%', md: '-8.5%' },
        left: { base: '55%', md: '5%' },
        width: { base: '140px', md: '192px' },
        height: { base: '100px', md: '137px' },
      },
    },
    defaultCarouselContainer: {
      cursor: 'pointer',
      height: '100%',

      '.carousel': {
        height: '100%',

        '.slider-wrapper': {
          height: '100%',

          '.slider': {
            height: '100%',

            '.slide': {
              height: '100%',
            },
          },
        },
      },
      _hover: {
        'img.media': {
          overflow: 'hidden',
          transform: { base: 'unset', md: 'scale(1.05)' },
        },
      },
    },
    imageBox: {
      display: 'flex',
      overflow: 'hidden',
      borderRadius: ['20px', '40px'],
      'img.media': {
        transition: 'transform .5s ease',
      },
    },
    arrowPrev: {
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      zIndex: '2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
    },
    arrowNext: {
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
    },
    modalContainer: {
      display: 'flex',
      bgColor: 'rgba(33,33,33,0.7)',
      position: 'fixed',
      top: '0',
      left: '0',
      w: '100%',
      h: '100vh',
      justifyContent: 'center',
      justify: 'center',
      alignItems: 'center',
      //header has zIndex 20
      zIndex: '21',
      padding: '1rem',
      margin: '0 !important',
      //ONG-4285 added z-index for modal to hide sticky menu
      '@media (min-width: 1024px)': {
        zIndex: '9999',
      },
    },
    gridModal: {
      display: 'grid',
      borderRadius: '20px',
      width: '100%',
      height: '100%',
      bgColor: 'black',
      opacity: '1',
      p: '1.2rem',
      color: 'white',
      gridTemplateColumns: 'repeat(4, 1fr) 0.7fr',
      gridTemplateRows: '6.75rem 1fr',
      gridColumnGap: '1.2rem',
      gridRowGap: '0px',
    },
    verticalCarousel: {
      '.carousel-wrapper': {
        height: '100% !important',
      },
      '.carousel': {
        height: '100% !important',
        position: 'absolute',
        top: '0',
        zIndex: 20,
      },
      '.carousel-slider': {
        zIndex: 30,
      },
      '.carousel-root': {
        height: '100% !important',
        position: 'relative',
      },
      '.slider-wrapper': {
        height: '100% !important',
        '.slider': {
          //verticalCarouselImage 9.5 + 1rem marginBottom
          height: '10.5rem !important',
        },
      },
    },
    modalHeader: {
      alignItems: 'center',
      width: '50%',
      display: 'flex',
    },
    exitButtonContainer: {
      display: 'flex',
      width: '55px',
      height: '55px',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    modalBigCarouselContainer: {
      height: '100%',
    },
    arrowPrevModalBigContainer: {
      position: 'absolute',
      top: '50%',
      left: '20px',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      zIndex: '2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
    },
    arrowNextModalBigContainer: {
      position: 'absolute',
      top: '50%',
      right: '20px',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
    },
    imagesModalBig: {
      padding: '0',
      height: '100%',
      overflow: 'hidden',
      borderRadius: {
        base: '20px',
        md: '40px',
        lg: '10px',
      },
    },
    arrowNextModalPositioner: {
      display: 'flex',
      mb: '0.5rem',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'absolute',
      bottom: '0',
      zIndex: '29',
    },
    arrowNextModalSmallContainer: {
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      zIndex: '31',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '33px',
      height: '33px',
    },
    arrowPrevModalPositioner: {
      display: 'flex',
      mt: '0.5rem',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      position: 'absolute',
      top: '0',
      zIndex: '29',
    },
    arrowPrevModalSmallContainer: {
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      zIndex: '31',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '33px',
      height: '33px',
    },
    imagesModalSmall: {
      mb: '1rem',
      display: 'flex',
      overflow: 'hidden',
      borderRadius: '10px',
      cursor: 'pointer',
      height: '9.5rem',
    },
    imagesModalSmallActive: {
      border: '1px solid #ecff18',
    },
  },
});
