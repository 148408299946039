import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'closeButtonWrapper',
  'closeButton',
  'saveButton',
  'headerContainer',
  'headerText',
  'headerBorder',
  'searchContainer',
  'bodyLinkContainer',
  'bodyContainer',
  'linkContainer',
  'linkArrow',
  'sticker',
  'bottomLinkContainer',
  'bottomLink',
  'langFlag',
  'level2Container',
  'level2HeaderContainer',
  'arrowBack',
  'level2LinkContainer',
  'level2TextAndIcon',
  'languageContainer',
  'languageToggle',
  'scrollContainer',
  'currencyContainer',
  'currencyItem',
  'currencyItemSelector',
  'currencyItemSelected',
  'currencyText',
  'input',
]);

export const menuTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      color: 'black',
      margin: { base: 0, sm: '10px' },
      paddingTop: { base: '10px', sm: '4rem' },
      borderRadius: { base: 0, sm: '20px' },
      overflow: 'hidden',
      WebkitTapHighlightColor: 'transparent',
      paddingBottom: { base: '2rem', sm: 0 },
    },
    closeButtonWrapper: {
      width: { base: '100%', md: 'auto' },
    },
    closeButton: {
      position: 'absolute',
      margin: '0 auto',
      left: { base: '50%', md: '20px' },
      marginLeft: { base: '-24px', md: 'auto' },
      top: { base: 'auto', md: '10px' },
      bottom: { base: '1.5rem', md: 'auto' },
      backgroundColor: '#212121',
      color: 'white',
      borderRadius: 'full',
      padding: '1rem',
      boxSize: '3rem',
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'all 0.3s ease-in-out',
      '&.active': {
        opacity: { base: 0, sm: 1 },
        transform: { base: 'translateY(-1rem)', sm: 'translateY(0)' },
      },
      zIndex: 2,
      svg: {
        width: '1rem',
        height: '1rem',
      },
    },
    saveButton: {
      borderRadius: 'full',
      padding: '1rem',
      color: 'white',
      backgroundColor: '#212121',
      width: '5rem',
      margin: '0 auto',
      fontSize: '12px',
      opacity: 0,
      transform: 'translateY(1rem)',
      transition: 'all 0.3s ease-in-out',
      '&.active': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
    headerContainer: {
      position: 'relative',
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '0.25rem',
      paddingRight: '0.75rem',
      marginX: { base: '10px', sm: '20px' },
      marginBottom: '0.4rem',
      marginTop: '20px',
      transition: 'opacity 0.2s ease-in-out',
      //HR-794
      // _after: {
      //   content: '""',
      //   position: 'absolute',
      //   bottom: '-0.4rem',
      //   left: '83%',
      //   height: '10px',
      //   borderWidth: '1px',
      //   borderColor: 'rgb(0,0,0)',
      //   zIndex: 500,
      // },
    },
    headerText: {
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: 'body',
      marginLeft: '1rem',
    },
    searchContainer: {
      position: 'absolute',
      left: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'white',
      padding: '0 0 0 0.5rem',
      zIndex: 1,
    },
    input: {
      paddingRight: '3.5rem',
      backgroundColor: 'transparent',
      _hover: { borderWidth: 0, boxShadow: 'none' },
      _focus: { borderWidth: 0, boxShadow: 'none' },
      _empty: { borderWidth: 0, boxShadow: 'none' },
      '::placeholder': { color: 'black' },
    },
    headerBorder: {
      position: 'absolute',
      bottom: '-0.4rem',
      height: '50%',
      width: '100%',
      borderWidth: '1px',
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderBottomLeftRadius: 'full',
      borderColor: 'black',
      pointerEvents: 'none',
      zIndex: 1,
      _after: {
        content: '""',
        position: 'absolute',
        bottom: '0px',
        left: '83%',
        height: '10px',
        borderWidth: '1px',
        borderColor: 'rgb(0,0,0)',
        zIndex: 2,
      },
    },
    headerSeparator: {
      position: 'absolute',
      bottom: '-0.3rem',
      right: '3.2rem',
      height: '10px',
      width: '1px',
      backgroundColor: 'black',
      zIndex: 10,
      visibility: 'hidden',
    },
    bodyContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
      overflow: 'auto',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingBottom: { base: '4rem', sm: 0 },
      bgSize: '250px 250px',
      bgRepeat: 'no-repeat',
      bgPosition: 'top 65% right -25%', // Background sticker placement, if visible
    },
    bodyLinkContainer: {
      paddingY: '1rem',
    },
    linkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: '0.3rem',
      padding: 0,
      fontFamily: 'body',
      fontSize: { base: '26px', lg: '30px' },
      _hover: { textDecoration: 'none' },
    },
    linkArrow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 'full',
      width: '30px',
      height: '30px',
      borderWidth: 1,
      borderColor: 'black',
    },
    bottomLinkContainer: {
      paddingY: '20px',
    },
    bottomLink: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      borderWidth: '1px',
      borderColor: 'black',
      width: 'fit-content',
      margin: '0.5rem 0',
      p: {
        letterSpacing: '-0.01em',
      },
    },
    langFlag: {
      height: '25px',
      width: '25px',
      marginLeft: '0.3rem',
      marginRight: '-0.3rem',
      overflow: 'hidden',
      borderRadius: 'full',
    },
    level2Container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      height: { base: '100%', sm: 'calc(100% - 20px)' },
      width: { base: '100%', sm: 'calc(100% - 20px)' },
      margin: { base: 0, sm: '10px' },
      paddingTop: { base: '10px', sm: '20px' },
      borderRadius: { base: 0, sm: '20px' },
      backgroundColor: 'white',
    },
    level2HeaderContainer: {
      position: 'relative',
      top: { base: '1rem', sm: '65px' },
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: { base: '0rem', sm: '0.25rem' },
      paddingRight: '0.75rem',
      marginX: { base: '10px', sm: '20px' },
      marginBottom: '0.4rem',
      marginTop: { base: '8px', lg: '4px' },
      backgroundColor: 'inherit',
      transition: 'opacity 0.2s ease-in-out',
      zIndex: 1,
      _after: {
        borderColor: 'blue',
      },
    },
    level2SearchContainer: {
      position: 'absolute',
      left: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: '0.75rem',
      zIndex: 1,
    },
    level2BodyContainer: {
      overflow: 'auto',
      height: '100%',
      paddingX: '20px',
      marginTop: { base: '10px', sm: '4rem' },
      paddingTop: { base: '0', md: '0.75rem' },
      paddingBottom: { base: '5rem', md: '1.5rem' },
    },
    arrowBack: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: 'body',
      marginLeft: '1rem',
    },
    level2LinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      _hover: { textDecoration: 'none' },
    },
    level2MainLinkDivider: {
      position: 'absolute',
      bottom: '-20px',
      left: 0,
      width: '100%',
      height: '1px',
      opacity: 0.3,
    },
    level2MainLink: {
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      alignItems: 'center',
      marginLeft: 0,
      marginBottom: '2rem',
      justifyContent: 'space-between',
      marginTop: { base: '10px', lg: '5px' },
    },
    level2TextAndIcon: {
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      alignItems: 'center',
      marginLeft: '1.5rem',
    },
    languageContainer: {
      display: 'flex',
      marginTop: { base: '1rem', sm: '4rem' },
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      boxShadow: 'lg',
      borderRadius: '20px',
      padding: '1.25rem 1.25rem',
      color: '#4E5356',
    },
    svgIcon: {
      height: 'fit-content',
      width: 'fit-content',
      minHeight: '2rem',
      minWidth: '2rem',
      svg: {
        stroke: 'inherit',
        path: {
          stroke: 'inherit!important',
        },
        circle: {
          stroke: 'inherit!important',
        },
      },
      path: {
        stroke: 'inherit',
      },
    },
    scrollContainer: {
      height: '90dvh',
      overflow: { base: 'scroll', md: 'auto' },
    },
    languageToggle: {
      position: 'relative',
      height: '50px',
      width: '100px',
      borderRadius: 'full',
      overflow: 'hidden',
      '.cutout': {
        zIndex: 1,
        position: 'relative',
        width: '100px',
        height: '50px',
        margin: '0 auto',
        overflow: 'hidden',
      },
      '.cutout:before': {
        content: '""',
        position: 'absolute',
        left: '4px',
        top: '4px',
        borderRadius: '100%',
        width: '42px',
        height: '42px',
        boxShadow: '0px 0px 0px 1000px rgba(0,0,0,0.2)',
        transition: 'transform 0.3s ease',
      },
    },
    currencyContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      boxShadow: 'lg',
      borderRadius: '20px',
      marginTop: '1.5rem',
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '0 1.25rem',
      color: '#4E5356',
    },
    currencyItem: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '18px',
      padding: '1.25rem 0',
      borderBottomWidth: '2px',
      borderStyle: 'dashed',
    },
    currencyItemSelector: {
      position: 'relative',
      height: '30px',
      width: '30px',
      borderWidth: '1px',
      borderRadius: '5px',
      borderColor: 'gray.300',
    },
    currencyItemSelected: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: '14px',
      width: '14px',
      backgroundColor: '#00FFC4',
      borderRadius: 'full',
    },
    currencyText: {
      padding: '1rem 0',
      fontSize: '14px',
    },
  },
});
