import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['linkList', 'linkItem']);

export const LinkListTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    linkList: {
      marginY: '1rem',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      gap: '0.75rem',
      padding: { base: '0 12px', lg: '0' },
      a: {
        p: {
          letterSpacing: '-0.01em',
        },
      },
    },
    linkItem: {
      color: 'darkGrey.700',
      padding: '0.5rem 0.75rem',
      borderWidth: '1px',
      borderColor: 'darkGrey.700',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
    },
  },
});
