import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'cardContainer',
  'imageContainer',
  'contentContainer',
  'titleAndLinkContainer',
  'title',
  'text',
]);

export const navigationCardListTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '1.25rem',
      padding: { base: '10px', lg: '30px' },
    },
    cardContainer: {
      display: 'flex',
      height: { base: '8.75rem', lg: '10rem' },
      width: '26rem',
      maxWidth: '100%',
      backgroundColor: 'white',
      borderRadius: '20px',
      borderBottomRightRadius: 0,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 6px',
      padding: { base: '5px', lg: '10px' },
      a: {
        width: '100%',
      },
      'a:hover': { textDecoration: 'none' },
    },
    imageAndContentContainer: {
      height: '100%',
    },
    imageContainer: {
      position: 'relative',
      height: '100%',
      minWidth: '113px',
      borderTopLeftRadius: { base: '15px', lg: '10px' },
      borderBottomLeftRadius: { base: '15px', lg: '10px' },
      overflow: 'hidden',
    },
    contentContainer: {
      flexGrow: 1,
      margin: '0 0.5rem 0 0.75rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '0.5rem 0',
    },
    titleAndLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      fontFamily: 'body',
      fontSize: { base: '20px', md: '28px' },
      color: 'black',
      lineHeight: '1.2',
    },
    text: {
      noOfLines: 3,
      fontSize: { base: '14px', md: '16px' },
    },
    icon: {
      borderRadius: 'full',
      border: '1px solid black',
    },
  },
});
