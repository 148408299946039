import { globalStyles } from './global';
import { breakpoints } from './breakpoints';
import { formTheme } from './components/form';
import { mapsTheme } from './components/maps';
import { menuTheme } from './components/menu';
import { extendTheme } from '@chakra-ui/react';
import { faqTheme } from './components/Faq/faq';
import { radioTheme } from './components/radio';
import { modalTheme } from './components/Modal';
import { inputTheme } from './components/input';
import { footerTheme } from './components/footer';
import { dividerTheme } from './components/divider';
import { PriceTagTheme } from './components/PriceTag';
import { checkboxTheme } from './components/checkbox';
import { LinkListTheme } from './components/linkList';
import { fonts, fontSizes, textStyles } from './fonts';
import { CTAButtonTheme } from './components/CTAButton';
import { containerTheme } from './components/container';
import { miniFaqTheme } from './components/Faq/miniFaq';
import { faqItemTheme } from './components/Faq/faqItem';
import { searchTheme } from './components/Search/search';
import { breadcrumbTheme } from './components/breadcrumb';
import { centerTextTheme } from './components/centerText';
import { slider1Theme } from './components/Slider1/Slider1';
import { slider2Theme } from './components/Slider2/Slider2';
import { singleImageTheme } from './components/singleImage';
import { textParagraphTheme } from './components/textParagraph';
import { HostelContainerTheme } from './components/hostelContainer';
import { slider1ItemTheme } from './components/Slider1/Slider1Item';
import { slider2ItemTheme } from './components/Slider2/Slider2Item';
import { searchResultTheme } from './components/Search/searchResult';
import { largeImageAndTextTheme } from './components/largeImageAndText';
import { groupReservationFormTheme } from './components/groupReservationForm';
import { eventCalendarTheme } from './components/EventCalendar/eventCalendar';
import { productCardListTheme } from './components/ProductCardList/ProductCardList';
import { productCardItemTheme } from './components/ProductCardList/ProductCardItem';
import { eventCalendarItemTheme } from './components/EventCalendar/eventCalendarItem';
import { productCardGalleryTheme } from './components/ProductCardList/ProductCardGallery';
import { navigationCardListTheme } from './components/NavigationCardList/navigationCardList';
import { hostelEventCalendarTheme } from './components/HostelEventCalendar/HostelEventCalendar';
import { tickerTheme } from './components/ticker';
import { videoComponentTheme } from './components/VideoComponent/videoComponent';
import { videoTheme } from './components/video';

export const base = extendTheme({
  fonts,
  fontSizes,
  textStyles,
  breakpoints,
  components: {
    CenterText: centerTextTheme,
    Checkbox: checkboxTheme,
    Container: containerTheme,
    Divider: dividerTheme,
    Footer: footerTheme,
    Form: formTheme,
    LinkList: LinkListTheme,
    LargeImageAndText: largeImageAndTextTheme,
    Radio: radioTheme,
    SingleImage: singleImageTheme,
    TextParagraph: textParagraphTheme,
    Slider1: slider1Theme,
    Slider1Item: slider1ItemTheme,
    Faq: faqTheme,
    MiniFaq: miniFaqTheme,
    FaqItem: faqItemTheme,
    Slider2: slider2Theme,
    Slider2Item: slider2ItemTheme,
    EventCalendar: eventCalendarTheme,
    EventCalendarItem: eventCalendarItemTheme,
    Maps: mapsTheme,
    NavigationCardList: navigationCardListTheme,
    ProductCardList: productCardListTheme,
    ProductCardItem: productCardItemTheme,
    ProductCardGallery: productCardGalleryTheme,
    Menu: menuTheme,
    PriceTag: PriceTagTheme,
    CTAButton: CTAButtonTheme,
    GroupReservationForm: groupReservationFormTheme,
    Modal: modalTheme,
    Breadcrumb: breadcrumbTheme,
    Search: searchTheme,
    SearchResult: searchResultTheme,
    Input: inputTheme,
    HostelContainer: HostelContainerTheme,
    HostelEventCalendar: hostelEventCalendarTheme,
    Ticker: tickerTheme,
    VideoComponent: videoComponentTheme,
    Video: videoTheme,
  },

  styles: {
    global: globalStyles,
  },

  config: {
    cssVarPrefix: 'ahhg',
  },
});
