import { defineStyleConfig } from '@chakra-ui/react';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-8px)',
  fontSize: '12px',
};

export const formTheme = defineStyleConfig({
  variants: {
    material: {
      container: {
        _focusWithin: {
          label: {
            transform: 'scale(0.85) translateY(-12px)',
            color: 'black',
          },
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
          {
            transform: 'scale(0.85) translateY(-12px)',
            color: 'gray.400',
          },
        input: {
          pt: 8,
          pb: 5,
        },
        label: {
          top: '7px',
          left: 0,
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: 'left top',
        },
      },
    },
    floating: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        'input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) ~ label, select + label': {
          ...activeLabelStyles,
        },
        'input::placeholder': {
          color: 'transparent',
        },
        input: {
          my: 2,
        },
        select: {
          my: 2,
          pl: 4,
        },
        '.chakra-form__error-message': {
          marginTop: 0,
        },
        label: {
          top: '14px',
          left: 0,
          zIndex: 2,
          ml: 2,
          position: 'absolute',
          pointerEvents: 'none',
          transformOrigin: 'left top',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },
        //country label now empty by default
        'label.countryLabel': {
          top: '14px',
          left: '-px',
          zIndex: 2,
          ml: 2,
          position: 'absolute',
          pointerEvents: 'none',
          transform: 'scale(0.85) translateY(-8px)',
          fontSize: '12px',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },
      },
    },
    floatingHostelCreator: {
      container: {
        _focusWithin: {
          label: {
            ...activeLabelStyles,
          },
        },
        'input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) ~ label, select + label': {
          ...activeLabelStyles,
        },
        'input::placeholder': {
          color: 'transparent',
        },
        'textarea::placeholder': {
          color: 'transparent',
        },
        input: {
          my: 2,
          borderColor: 'darkGrey.700',
        },
        textarea: {
          my: 2,
          borderColor: 'darkGrey.700',
        },
        select: {
          my: 2,
          pl: 4,
          borderColor: 'darkGrey.700',
        },
        '.chakra-form__error-message': {
          marginTop: 0,
        },
        'label:not(.selectLabel)': {
          top: '14px',
          left: 0,
          zIndex: -1,
          ml: 2,
          position: 'absolute',
          pointerEvents: 'none',
          transformOrigin: 'left top',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },
        'label.selectLabel': {
          top: '14px',
          left: '-px',
          zIndex: -1,
          ml: 2,
          position: 'absolute',
          pointerEvents: 'none',
          transform: 'scale(0.85) translateY(-8px)',
          fontSize: '12px',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
});
