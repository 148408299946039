import { defineStyleConfig } from '@chakra-ui/react';

/**
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/heading.ts
 */
export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
  },
  sizes: {
    xl: {
      fontSize: ['7xl', null, '10xl'],
    },
    lg: {
      fontSize: ['4xl', null, '7xl'],
    },
    md: {
      letterSpacing: ['-0.04em', null, '-0.03em'],
      fontSize: ['2xl', null, '4xl'],
    },
    sm: {
      letterSpacing: ['-0.04em', null, '-0.03em'],
      fontSize: ['xl', null, '3xl'],
    },
  },
});
