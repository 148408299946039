import { defineStyleConfig } from '@chakra-ui/styled-system';

export const eventCalendarTheme = defineStyleConfig({
  baseStyle: {
    container: {
      width: '100vw',
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      // padding: { base: '2rem 0.75rem !important', lg: '2rem !important' },
      overflow: 'hidden',
      '.arrowContainer': {
        position: 'relative',
      },
      '.hiddenControls': {
        position: 'relative',
        '.image-eventCalendar-button-next': {
          display: 'none',
        },
        '.image-eventCalendar-button-prev': {
          display: 'none',
        },
      },
      '.sliderContainer': {
        marginTop: '2rem',
        overflow: 'visible',
        outline: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: { base: '0', lg: '1.25rem' },
      },
      '.swiper-scrollbar': {
        position: 'static',
        height: '1px',
        marginTop: { base: '1.5rem', lg: '3.25rem' },
        cursor: 'pointer',
        // maxWidth: {
        //   base: 'calc(100% - 12px)',
        //   md: 'calc(100% - 24px)',
        // },
        _before: {
          content: '" "',
          display: 'flex',
          width: '100%',
          height: '1px',
          alignSelf: 'center',
          marginBottom: '-1px',
          color: 'white',
        },
      },
      '.swiper-scrollbar-drag': {
        // marginTop: '-5px',
        height: '1px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'grab',
        // backgroundColor: 'white',
        _active: { cursor: 'grabbing' },
        _before: {
          content: '" "',
          display: 'flex',
          width: '100%',
          height: '1px',
          color: 'white',
        },
      },
    },
    divider: {
      height: '1.25rem',
      marginBottom: '-0.85rem',
      borderTopLeftRadius: 'full',
      borderWidth: '1px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: 'darkGrey.700',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    headingText: {
      padding: { base: '0.5rem 0.25rem 0.5rem 1.25rem', lg: '1rem 0.5rem 1rem 1.75rem' },
      color: 'darkGrey.700',
      minWidth: 'fit-content',
    },
    datesText: {
      padding: { base: '0.5rem 0 0.5rem 0.25rem', lg: '1rem 0 1rem 0.5rem' },
      fontFamily: 'body',
      color: 'darkGrey.700',
      minWidth: 'fit-content',
    },
    buttonsContainer: {
      display: 'flex',
      maxWidth: 'fit-content',
      overflow: 'auto',
      width: 'calc(100% + 24px)',
      marginLeft: { base: '-12px', lg: 0 },
      paddingLeft: { base: '6px', lg: 0 },
      '::-webkit-scrollbar': { display: 'none' }, // hide scrollbar (Chrome, Safari, Opera)
      scrollbarWidth: 'none', // hide scrollbar (Firefox)
      msOverflowStyle: 'none', // hide scrollbar (IE, Edge)
      WebkitTapHighlightColor: 'transparent',
    },
    dayButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: '1px',
      borderRadius: '100px',
      padding: '0.5rem 1rem',
      backgroundColor: 'transparent',
      color: 'darkGrey.700',
      marginX: '0.4rem',
      fontSize: '14px',
      textTransform: 'capitalize',
      _first: { textTransform: 'unset' },
      whiteSpace: 'nowrap',
      _hover: {
        backgroundColor: 'transparent',
        borderColor: '#4E5356',
      },
      letterSpacing: '-0.01em',
    },
    noEvents: {
      height: { base: '13.125rem', md: '16.25rem' },
      textAlign: 'center',
      color: 'darkGrey.700',
    },
    arrowPrev: {
      position: 'absolute',
      top: 'calc(100% - 40px)',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
    arrowNext: {
      position: 'absolute',
      top: 'calc(100% - 40px)',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  },
});
