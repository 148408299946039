import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const dividerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      px: 2.5,
      display: 'block',
      borderTopLeftRadius: 16,
      borderTop: '1px solid',
      borderLeft: '1px solid',
      content: '""',
      h: '1rem',
    },
  },
});
