import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['header', 'spacer', 'burgerMenu', 'countrySelector']);

export const headerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: ['73px', '100px'],
      position: { base: 'static', md: 'fixed' },
      top: '0',
      left: '0',
      transition: { base: 'none', md: 'top 0.7s ease-in-out' },
      zIndex: '20',
      maxHeight: ['73px', '100px'],
      padding: ['0', '30px'],

      img: {
        maxHeight: '60px',
        width: ['120px', '160px'],
        margin: '0.375rem',
      },
    },
    spacer: {
      width: '100%',
      height: { base: '73px', md: '100px' },
      display: { base: 'none', md: 'block' },
    },
    burgerMenu: {
      display: {
        base: 'none',
        lg: 'flex',
      },
      flexDirection: 'column',
      width: 14,
      height: 14,
      background: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      p: {
        letterSpacing: '0.08em',
      },
    },
    countrySelector: {
      display: {
        base: 'none',
        lg: 'flex',
      },
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
      p: {
        fontSize: '12px',
      },
    },
    iconContainer: {
      border: '1px white solid',
      borderRadius: '50%',
    },
  },
});
