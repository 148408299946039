import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'heading', 'divider', 'resultCount', 'searchResults']);

export const searchTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      padding: '0.75rem',
      maxWidth: '56.5rem',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: 'darkGrey.700',
    },
    heading: {
      textAlign: 'center',
      lineHeight: '100%',
      marginBottom: { base: '2.25rem', md: '4rem', lg: '7.5rem' },
    },
    input: {
      paddingTop: '0 !important',
      paddingBottom: '0.2rem !important',
      paddingLeft: '3rem',
      paddingRight: '3.5rem',
      backgroundColor: 'transparent',
      _hover: { borderWidth: 0, boxShadow: 'none' },
      _focus: { borderWidth: 0, boxShadow: 'none' },
      _empty: { borderWidth: 0, boxShadow: 'none' },
      '::placeholder': { color: 'darkGrey.700' },
    },
    divider: {
      height: '1.25rem',
      marginTop: '-1rem',
      marginBottom: '0.5rem',
      borderBottomLeftRadius: 'full',
      borderWidth: '1px',
      borderTopWidth: 0,
      borderRightWidth: 0,
      borderColor: 'currentcolor',
    },
    resultCount: {
      textAlign: 'center',
      marginTop: { base: '0.75rem', md: '1.5rem' },
      marginBottom: { base: '1.563rem', md: '5rem' },
    },
    searchResults: {},
    searchButton: {
      position: 'relative',
      backgroundColor: 'black',
      borderBottomRightRadius: 0,
      overflow: 'hidden',
      fontWeight: '600!important',
      padding: '16px 0!important',
      borderRadius: '0px 50px 50px 50px!important',
      width: ['351px', '380px'],
      whiteSpace: 'nowrap',
      _after: {
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: 'black',
        opacity: 0,
        transition: '0.25s linear',
        zIndex: 0,
      },
      _hover: {
        _after: {
          opacity: 0.1,
          transition: '0.25s linear',
        },
        '.text': {
          transform: 'translateY(-110%)',
          transition: '0.25s linear',
        },
        '.revert-text': {
          transform: 'translateY(-100%)',
          transition: '0.25s linear',
        },
      },
    },
    text: {
      color: 'white',
      transition: '0.25s linear',
      zIndex: 1,
      lineHeight: '16px',
      fontSize: '14px',
    },
    revertText: {
      position: 'absolute',
      width: '100%',
      color: 'white',
      transition: '0.25s ease-in-out',
      zIndex: 1,
      lineHeight: '1',
      fontSize: '14px',
    },
  },
});
