import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
// import { singleImageTheme as baseSingleImageTheme } from 'themes/base/components/singleImage';

const helpers = createMultiStyleConfigHelpers(['container', 'sticker', 'image']);

export const singleImageTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    sticker: {
      position: 'absolute',
      top: { base: '-9.5%', md: '-9.5%' },
      left: { base: '70%', md: '5%' },
      width: { base: '110px', md: '230px' },
      height: { base: '110px', md: '230px' },
    },
    image: {
      aspectRatio: '1/1',
      position: 'relative',
      // margin: '0 auto',
      minHeight: 'auto',
      maxWidth: '100%',
      overflow: 'hidden',
      borderRightRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    priceTag: {
      position: 'absolute',
      top: { base: '-8.5%', md: '-8.5%' },
      left: { base: '55%', md: '5%' },
      width: { base: '140px', md: '192px' },
      height: { base: '100px', md: '137px' },
    },
  },
  // let size be determined by gridParameters on the container
  // sizes: baseSingleImageTheme.sizes,
  // defaultProps: baseSingleImageTheme.defaultProps,
});
