import { defineStyleConfig } from '@chakra-ui/react';

export const poiIconTheme = defineStyleConfig({
  baseStyle: {
    color: 'yellow.500',
  },
  variants: {
    outlined: {
      color: 'grey.500',
      '& g': {
        stroke: 'yellow.500',
      },
    },
  },
});
