import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'sticker', 'image']);

export const videoTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      video: {
        borderRadius: { base: '20px', lg: '40px' },
      },
    },
    sticker: {
      position: 'absolute',
      top: { base: '-9.5%', md: '-9.5%' },
      left: { base: '70%', md: '5%' },
      width: { base: '110px', md: '230px' },
      height: { base: '110px', md: '230px' },
    },
    image: {
      position: 'relative',
      height: 'inherit',
      width: 'inherit',
    },
  },
});
