import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'section',
  'sectionTitle',
  'namesContainer',
  'contactsContainer',
  'extraInfoContainer',
  'divider',
  'policyNotChecked',
]);

export const groupReservationFormTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: '100%',
      maxWidth: '84rem',
      padding: { base: '0.5rem', md: '1rem' },
      color: '#4E5356',
      input: { backgroundColor: 'white', borderColor: '#4E5356' },
      textarea: { backgroundColor: 'white', borderColor: '#4E5356' },
      select: { height: '3.375rem', backgroundColor: 'white', borderColor: '#4E5356' },
      label: { zIndex: 1, color: '#4E5356 !important' },
    },
    section: {
      display: 'flex',
      flexDirection: { base: 'column', lg: 'row' },
    },
    sectionTitle: {
      minWidth: '25rem',
      maxWidth: { base: undefined, lg: '25rem' },
      marginRight: { base: 0, lg: '2rem' },
      marginBottom: { base: '1rem', lg: 0 },
    },
    namesContainer: {
      display: 'flex',
      flexDirection: { base: 'column', lg: 'row' },
    },
    extraInfoContainer: {
      display: 'flex',
      flexDirection: { base: 'column', xl: 'row' },
    },
    contactsContainer: {
      display: 'flex',
      flexDirection: { base: 'column', lg: 'row' },
      marginTop: { base: '0.75rem', lg: '1.5rem' },
    },
    datesContainer: {
      display: 'flex',
      flexDirection: { base: 'column', xl: 'row' },
      marginTop: { base: '0.75rem', lg: '1.5rem' },
    },
    inputMargin: {
      marginTop: { base: '0.75rem', lg: 0 },
      marginLeft: { base: 0, lg: '1.5rem' },
    },
    inputNotValid: {
      _focusVisible: {
        borderColor: 'red.500!important',
        boxShadow: '0 0 0 1px red!important',
      },
    },
    dateControl: {
      zIndex: { base: 'unset', lg: 5 },
      paddingBottom: 0,
      height: '55px',
      borderRadius: '5px',
      boxShadow: '0 0 0 1px #4E5356',
      label: {
        position: 'relative',
        top: '-3.5rem',
        left: '1rem',
        fontSize: '14px',
      },
    },
    selectInput: {
      select: { paddingTop: '1rem' },
      width: { base: '100%', xl: '50%' },
      marginTop: { base: '0.75rem', lg: '1.5rem', xl: 0 },
      marginLeft: { base: 0, xl: '1.5rem' },
    },
    radioInput: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '1.5rem',
    },
    policyContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTopWidth: '2px',
      borderStyle: 'dashed',
      marginTop: '1.25rem',
      paddingTop: '1.25rem',
    },
    policyNotChecked: {
      color: 'red.500',
    },
    divider: {
      height: '1.25rem',
      marginBottom: '-0.75rem',
      borderTopLeftRadius: 'full',
      borderWidth: '1px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: '#4E5356',
    },
    button: {
      backgroundColor: 'black',
      color: 'white',
    },
  },
});
