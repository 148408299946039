import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'headline', 'button']);

export const miniFaqTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      //width from bootstrap-wrapper ruins spacing
      width: { base: 'unset!important', lg: '100%!important' },
      color: 'darkGrey.700',
      position: 'relative',
      marginLeft: '11px',
      letterSpacing: '-0.03em',
      _first: {
        _before: {
          display: 'block',
          borderTopLeftRadius: 16,
          borderTop: '1px solid',
          borderLeft: '1px solid',
          content: '""',
          w: { base: 'calc(100% + 10px)', lg: '100%' },
          h: '0.8rem',
          position: 'relative',
          left: { base: -2.5, lg: '-12px' },
          mb: -1.5,
        },
      },
    },
    headline: {
      fontSize: 24,
    },
    button: {
      mt: ['1.25rem', '2rem'],
    },
  },
});
