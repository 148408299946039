import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'closeButton',
  'loadingIcon',
  'hostelMarker',
  'POIMarker',
  'markerText',
  'POIImage',
  'POITitle',
  'POIDescription',
  'POILink',
]);

export const mapsTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      height: '42rem',
      width: { base: '100%', lg: '42rem' },
      maxWidth: { base: '100%', lg: 'calc(100vw - 4rem)' },
      maxHeight: '125vw',
      borderRadius: { base: '20px', lg: '40px' },
      overflow: 'hidden',
      '& > div': {
        borderRadius: { base: '20px', lg: '40px' },
      },
      '& div div .gm-style': {
        borderRadius: { base: '20px', lg: '40px' },
        overflow: 'hidden',
      },
    },
    closeButton: {
      position: 'absolute',
      zIndex: 1,
      top: '-1rem',
      right: '-1rem',
      height: '2.188rem',
      width: '2.188rem',
      backgroundColor: '#212121',
      color: 'white',
      borderRadius: 'full',
      marginTop: '0.3rem',
      marginRight: '0.3rem',
      padding: '0',
    },
    loadingIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    hostelMarker: {
      height: '1.875rem',
      width: '1.875rem',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      backgroundColor: 'green.200',
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: '-2.15rem',
      left: '-0.95rem',
      boxShadow: 'md',
    },
    POIMarker: {
      height: '1.25rem',
      width: '1.25rem',
      backgroundColor: 'darkGrey.600',
      borderColor: 'green.500',
      borderWidth: '2px',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: '-1.4rem',
      left: '-0.625rem',
      boxShadow: 'md',
      opacity: 1,
      zIndex: 2,
    },
    markerText: {
      position: 'absolute',
      top: '0.35rem',
      left: '50%',
      transform: 'translate(-50%, 0)',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      color: 'darkGrey.700',
      fontWeight: 'bold',
      letterSpacing: '-0.03em',
    },
    POIImage: {
      position: 'relative',
      width: '100%',
      height: '6.25rem',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      overflow: 'hidden',
    },
    POITitle: {
      fontSize: '18px',
      fontFamily: 'body',
      marginTop: '1rem',
      padding: '0 0.5rem',
      letterSpacing: '-0.03em',
    },
    POIDescription: {
      fontSize: '12px',
      fontFamily: 'body',
      marginTop: '0.5rem',
      marginBottom: '0.75rem',
      padding: '0 0.5rem',
      letterSpacing: '-0.03em',
    },
    POILink: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.5rem',
      marginBottom: '0.5rem',
    },
    POILinkDescription: {
      fontSize: '12px',
      fontFamily: 'body',
      fontWeight: 'bold',
      marginLeft: '0.5rem',
    },
  },
});
