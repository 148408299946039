import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'closeButton']);

export const modalTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      padding: '1.25rem',
      margin: '1.25rem',
      borderRadius: '20px',
    },
    title: {
      padding: '0 1.25rem',
      fontFamily: 'body',
      fontSize: '28px',
    },
    message: {
      padding: '0 1.25rem',
      fontFamily: 'body',
      fontSize: '18px',
    },
    closeButton: {
      bottom: '1rem',
      backgroundColor: '#212121',
      color: 'white',
      borderRadius: 'full',
      marginTop: '0.3rem',
      marginRight: '0.3rem',
      padding: '1rem',
      boxSize: '3rem',
    },
    divider: {
      height: '1.25rem',
      marginTop: '1.25rem',
      borderTopLeftRadius: 'full',
      borderWidth: '1px 0px 0px 1px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: '#4E5356',
    },
    button: {
      backgroundColor: 'green.200',
      color: 'black',
    },
    formContainer: {
      padding: '1rem',
      fontFamily: 'body',
      fontSize: '18px',
    },
  },
});
