import { keyframes } from '@chakra-ui/react';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  
  to {
    opacity: 0;
  }
`;

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  
  to {
    opacity: 0;
    transform: translateY(-100px);
  }
`;

export const slideUp = keyframes`
  0% {
    transform: translateY(0%)
  }

  50% {
    transform: translateY(200%)
  }

  51% {
    transform: translateY(-200%)
  }

  0% {
    transform: translateY(0%)
  }
`;

export const slideIn = keyframes`
0% {
    transform: translateX(-1000px);
}
100% {
    transform: translateX(0);
}
`;
