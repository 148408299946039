import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const instagramSocialWallTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      padding: '1.5rem 1rem 0rem 1rem',
      color: '#9B7A31',
    },
    heading: {
      fontSize: {
        base: '40px',
        sm: '80px',
        md: '120px',
      },
      fontFamily: 'heading',
      lineHeight: '105%',
      marginTop: '-0.5rem',
    },
    elfsightContainer: {
      marginTop: { base: '2rem', lg: '3rem' },
    },
    gridContainer: {
      position: 'relative',
      display: 'flex',
      marginTop: '4rem',
    },
    gridSubContainer: {
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      width: '50%',
    },
    column: {
      width: { base: '100%', md: '50%' },
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: { base: '2rem', md: '4rem' },
    },
    //sticker when positioned absolute
    // stickerContainer: {
    //   position: 'absolute',
    //   width: { base: '50%', md: '25%' },
    //   height: { base: '7rem', md: '9rem' },
    //   top: '-4rem',
    //   left: { base: '25%', md: '12.5%' },
    //   transform: 'translate(-50%, 0)',
    // },
    sticker: {
      position: 'relative',
      height: '100%',
      width: '100%',
      // transform: { base: 'scale(1.2)', md: 'scale(1)', lg: 'scale(1)' },
    },
  },
});
