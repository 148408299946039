import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'headerAnchorOffset',
  'galleryContainer',
  'mainImageContainer',
  'otherImagesContainer',
  'smallImageContainer',
  'galleryCountOverlay',
  'galleryCountText',
  'headline',
  'text',
  'amenitiesContainer',
  'amenityItem',
  'iconContainer',
  'icon',
  'amenityText',
]);

export const productCardItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: { base: '100%', md: '26rem' },
      maxWidth: '26rem',
      borderRadius: '20px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 6px',
      padding: '10px',
      backgroundColor: 'white',
      letterSpacing: '-0.03em',
    },
    headerAnchorOffset: {
      height: '4.5rem',
      marginTop: '-4.5rem',
      visibility: 'hidden',
    },
    galleryContainer: {
      display: 'flex',
      width: '100%',
      height: { base: '15rem', lg: '18.75rem' },
      borderRadius: '10px',
      overflow: 'hidden',
      WebkitTapHighlightColor: 'transparent',
    },
    mainImageContainer: {
      position: 'relative',
      width: '70%',
      height: '100%',
      marginRight: '1px',
    },
    label: {
      position: 'absolute',
      bottom: '0.5rem',
      left: '0.5rem',
      padding: '0.25rem 0.5rem',
      borderRadius: '0 1rem 1rem 7px',
      backgroundColor: '#7000FF',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: 'uppercase',
      zIndex: 1,
      letterSpacing: '-0.03em',
    },
    otherImagesContainer: {
      width: '30%',
      height: '100%',
    },
    smallImageContainer: {
      position: 'relative',
      height: '33.33%',
      width: '100%',
      marginBottom: '1px',
    },
    lastSmallImageCounter: {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontSize: '24px',
      backgroundColor: 'rgba(0,0,0,0.6)',
    },
    galleryCountOverlay: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      zIndex: 1,
    },
    galleryCountText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '24px',
      pointerEvents: 'none',
    },
    headline: {
      marginTop: '1.25rem',
      padding: '0 10px',
      fontSize: '28px',
      lineHeight: '34px',
      fontFamily: 'body',
      '.sc-rich-text': {
        padding: '0',
      },
    },
    text: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0.25rem',
      padding: '0 10px',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      '.sc-rich-text': {
        whiteSpace: 'normal',
        fontSize: { base: '16px', lg: '18px' },
        padding: '0',
        p: {
          _last: {
            marginBottom: '0',
          },
        },
      },
    },
    readMore: {
      position: 'absolute',
      bottom: '-1px',
      right: '10px',
      background:
        'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6083026960784313) 17%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%)',
      paddingLeft: '5rem',
      fontWeight: 'bold',
      WebkitTapHighlightColor: 'transparent',
    },
    amenitiesContainer: {
      margin: '1.25rem 10px 0 10px',
      padding: '1.25rem 0',
      borderTopWidth: '1px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1rem',
    },
    amenityItem: {
      display: 'flex',
      width: 'calc(50% - 0.5rem)',
    },
    iconContainer: {
      height: '3.75rem',
      width: '3.75rem',
      position: 'absolute',
      top: '-1.8rem',
      left: '1rem',
      zIndex: 1,
      backgroundColor: 'white',
      borderRadius: 'full',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      height: '1.25rem',
      width: '1.25rem',
      color: 'black',
    },
    amenityText: {
      color: 'black',
      fontSize: '14px',
      lineHeight: '20px',
      marginLeft: '0.75rem',
      width: 'calc(100% - 2rem)',
    },
  },
});
