import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const heroTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      padding: ['0 0.625rem 0.625rem 0.625rem', '0 1.75rem 1.75rem 1.75rem'],
      marginTop: '12px!important',
    },
    card: {
      position: 'relative',
      height: 'fit-content',
      marginBottom: ['2rem', '0'],
      maxHeight: ['calc(100vh - 150px)', '737px'],
      '.media': {
        height: ['100vh', ''],
        maxHeight: ['calc(100vh - 110px)', '737px'],
        objectFit: 'cover',
        width: '100%',
        borderRadius: ['20px', '40px'],
      },

      '.sticker': {
        position: 'absolute',
        top: 0,
        right: 0,
        width: ['150px', '220px'],
        height: ['150px', '200px'],
      },
      '.priceTag': {
        position: 'absolute',
        top: 0,
        right: 0,
        width: { base: '140px', md: '192px' },
        height: { base: '100px', md: '137px' },
      },
    },
    textFields: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      flexDirection: 'column',
      top: ['', 0],
      bottom: ['15%', ''],
      width: '100%',
      height: ['', '100%'],
      color: 'white',
      textAlign: 'center',

      '.title': {
        maxWidth: ['356px', '774px'],
        fontSize: ['3.75rem', '7.5rem'],
        fontWeight: '400',
        lineHeight: ['3.75rem', '7.5rem'],
        marginBottom: [1, 7],
      },
      '.subtitle': {
        maxWidth: ['248px', '774px'],
        fontFamily: 'Inter',
        fontSize: ['16px', '28px'],
        lineHeight: ['22px', '33.89px'],
        marginTop: '10px',
        letterSpacing: '-0.06rem',
      },

      '.trumpet': {
        fontFamily: 'Inter',
        fontSize: ['16px', '18px'],
        lineHeight: ['16px', '18px'],
        textTransform: 'uppercase',

        '.text': {
          paddingRight: '1rem',
        },
      },
    },
    headline: {
      display: 'flex',
      flexDirection: 'column',
      transition: 'top 0.5s, bottom 0.5s',
      fontSize: ['60px', '120px'],
      fontWeight: '400',
      lineHeight: ['60px', '120px'],
      fontFamily: 'Anton',
      textTransform: 'uppercase',
      letterSpacing: '0.125rem',

      '.upperText': {
        animationDelay: '1s',
      },
      '.lowerText': {
        animationDelay: '1s',
      },
    },
  },
});
