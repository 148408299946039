import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['header', 'spacer', 'burgerMenu', 'countrySelector']);

export const headerTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: ['', '120px'],
      position: { base: 'static', md: 'fixed' },
      top: '0',
      left: '0',
      transition: { base: 'none', md: 'top 0.7s ease-in-out' },
      zIndex: '20',
      maxHeight: ['', '120px'],
      padding: ['0', '30px'],

      '.image': {
        transform: ['', 'translateY(0.75rem)'],
        borderRadius: '0px 0px 10px 10px',
        width: ['128px', '144px'],
        img: {
          margin: '1rem',
        },
      },

      '&.is-homepage': {
        position: { base: 'absolute', md: 'fixed' },
        backgroundColor: () => {
          return { base: 'transparent', md: 'offGrey.500' };
        },
        '.image': {
          backgroundColor: { md: 'offGrey.500' },
          img: {
            paddingTop: { base: '1rem', md: '0' },
          },
        },
      },
    },
    spacer: {
      width: '100%',
      height: { base: '73px', md: '120px' },
      display: { base: 'none', md: 'block' },

      '&.is-homepage': {
        height: '48px',
      },
    },
    burgerMenu: {
      display: {
        base: 'none',
        lg: 'flex',
      },
      flexDirection: 'column',
      width: 14,
      height: 14,
      background: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      p: {
        letterSpacing: '0.08em',
      },
    },
    countrySelector: {
      display: {
        base: 'none',
        lg: 'flex',
      },
      justifyContent: 'space-around',
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
      p: {
        fontSize: '12px',
      },
    },
    iconContainer: {
      border: '1px white solid',
      borderRadius: '50%',
    },
    breadcrumbContainer: {
      marginTop: { base: '-6px', lg: '0px' },
      paddingTop: { base: '0px', lg: '10px' },
    },
  },
});
