import { textStyles } from './fonts';

export const globalStyles = {
  h3: { letterSpacing: '-0.03em' },
  h4: { letterSpacing: '-0.03em' },
  h5: { letterSpacing: '-0.03em' },
  p: { letterSpacing: '-0.03em' },
  '.sc-rich-text': {
    ...textStyles,
    padding: { base: '0 12px', lg: 'unset' },
  },
  '.hostelGroupingContainer': {
    '.rte-left-margin': {
      paddingLeft: { base: '12px', lg: '32px !important' },
    },
    '.sc-rich-text': {
      padding: { base: '0' },
    },
  },
  '.rte-left-margin': {
    paddingLeft: { base: '0px', lg: '56px !important' },
  },
  '.top-margin': {
    marginTop: '1rem',
  },
  '.ahhg-text-paragraph': {
    h3: {
      marginBottom: '1rem',
    },
    h4: {
      marginBottom: '1rem',
    },
    h5: {
      marginBottom: '1rem',
    },
  },
  '.ahhg-center-text': {
    marginTop: { base: '0!important', lg: '24px' },
    h4: {
      marginBottom: '1rem',
    },
    h5: {
      marginBottom: '1rem',
    },
  },
  '.ahhg-large-image-and-text': {
    '.sc-rich-text': {
      a: {
        _hover: {
          textShadow: '0 0 0 #fff',
        },
      },
    },
  },
  '.small-text': { fontSize: '12px !important' },
  main: {
    overflow: 'hidden',
  },
  '.bootstrap-wrapper .row > *': {
    paddingX: { base: '12px', md: '24px' },
  },
  '.plusminus': {
    position: 'relative',
    width: '14px',
    height: '14px',
    '&.activePlusMinus': {
      _before: {
        transform: 'translatey(-50%) rotate(-90deg)',
        opacity: 0,
      },
      _after: {
        transform: 'translatey(-50%) rotate(0)',
        // backgroundColor: 'darkGrey.700',
      },
    },
    _before: {
      content: '""',
      display: 'block',
      backgroundColor: '#fff',
      position: 'absolute',
      top: '50%',
      left: 0,
      transition: '.35s',
      width: '100%',
      height: '1px',
      transform: 'translatey(-50%)',
    },
    _after: {
      content: '""',
      display: 'block',
      backgroundColor: '#fff',
      position: 'absolute',
      top: '50%',
      left: 0,
      transition: '.35s',
      width: '100%',
      height: '1px',
      transform: 'translatey(-50%) rotate(90deg)',
    },
  },
};
