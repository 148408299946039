import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'divider',
  'categoryText',
  'showMoreContainer',
  'showMoreIcon',
  'showMoreText',
]);

export const slider1Theme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: '100vw',
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: { base: '2rem 0.75rem', lg: '2rem' },
      overflow: 'hidden',
      '.sliderContainer': {
        marginTop: '2rem',
        overflow: 'visible',
        outline: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      backgroundColor: 'transparent',
      '.swiper-scrollbar': {
        position: 'static',
        height: '8px',
        marginTop: '2rem',
        cursor: 'pointer',
        paddingTop: '3px',
        // maxWidth: {
        //   base: 'calc(100% - 12px)',
        //   md: 'calc(100% - 24px)',
        // },
        _before: {
          content: '" "',
          display: 'flex',
          width: '100%',
          height: '1px',
          alignSelf: 'center',
          marginBottom: '-1px',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: 'white',
        },
      },
      '.swiper-scrollbar-drag': {
        marginTop: '-5px',
        height: '8px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'grab',
        _active: { cursor: 'grabbing' },
        _before: {
          content: '" "',
          display: 'flex',
          width: '100%',
          height: '1px',
          backgroundColor: 'white',
          color: 'white',
        },
      },
      '.swiper-wrapper': {
        WebkitBackfaceVisibility: 'hidden',
      },
      '.swiper-slide': {
        WebkitBackfaceVisibility: 'hidden',
      },
    },
    divider: {
      height: '1.25rem',
      marginBottom: '-0.85rem',
      borderTopLeftRadius: 'full',
      borderWidth: '1px',
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: 'black',
    },
    categoryText: {
      padding: { base: '0.5rem 1.25rem', lg: '1rem 1.75rem' },
      color: 'black',
    },
    showMoreContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      height: '100%',
      minHeight: '14rem',
      width: '8rem',
      maxWidth: '90%',
      marginRight: { base: '1rem', md: '2rem' },
      borderRadius: '20px',
      borderTopLeftRadius: 0,
      cursor: 'pointer',
      _hover: { textDecoration: 'none' },
    },
    showMoreIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '5rem',
      height: '5rem',
      backgroundColor: 'white',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      borderRadius: 'full',
    },
    showMoreText: {
      marginTop: '0.75rem',
      fontWeight: 600,
      fontSize: { base: '14px', md: '16' },
      whiteSpace: 'nowrap',
    },
  },
});
