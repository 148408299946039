export const colors = {
  transparent: 'transparent',
  black: { '500': '#212121' },
  white: { '500': '#fff' },
  canvas: { '500': '#FFFFF6' },
  overlay: { '500': 'rgba(33,33,33,0.7)' },
  border: { '500': 'rgba(33,33,33,0.5)' },
  green: {
    '50': '#d8fff1',
    '100': '#9bffdb',
    '200': '#00FFC4', // Brand Next Green
    '300': '#00fbac',
    '400': '#00f39b',
    '500': '#00ec89',
    '600': '#00da7c',
    '700': '#00c56d',
    '800': '#00b35f',
    '900': '#009047',
  },
  lightRosa: {
    '50': '#F7E4DF', // Brand Light Rosa
    '100': '#f0c2a9',
    '200': '#e39b73',
    '300': '#d6773b',
    '400': '#cd5e01',
    '500': '#c44600',
    '600': '#bb4100',
    '700': '#af3a00',
    '800': '#a23300',
    '900': '#8c2500',
  },
  darkGrey: {
    '50': '#f3f9fc',
    '100': '#eaf0f4',
    '200': '#dfe5e8',
    '300': '#cdd3d7',
    '400': '#a9aeb2',
    '500': '#888e91',
    '600': '#616669',
    '700': '#4E5356', // Brand Dark Grey
    '800': '#303538',
    '900': '#101518',
  },
  lightGreen: {
    '50': '#edf1e4',
    '100': '#D2DDBB', // Brand Light Green
    '200': '#b4c68e',
    '300': '#97b161',
    '400': '#82a340',
    '500': '#6d951a',
    '600': '#5f8713',
    '700': '#4d7509',
    '800': '#3a6201',
    '900': '#194300',
  },
  lightGrey: {
    '50': '#f9f9f4',
    '100': '#f2f2ed',
    '200': '#E9E9E4', // Brand Light Grey
    '300': '#d9d9d4',
    '400': '#b5b5b0',
    '500': '#959591',
    '600': '#6d6d69',
    '700': '#595955',
    '800': '#3b3b37',
    '900': '#1b1b17',
  },
  brokenYellow: {
    '50': '#faf4df',
    '100': '#F3E1Ad', // Brand Broken Yellow
    '200': '#ebce77',
    '300': '#e3bd3d',
    '400': '#dfaf01',
    '500': '#daa200',
    '600': '#da9600',
    '700': '#da8400',
    '800': '#da7300',
    '900': '#d95400',
  },
  purple: {
    '50': '#ebeaff',
    '100': '#cdcbff',
    '200': '#aba8ff',
    '300': '#8885FF', // Brand Purple
    '400': '#6d66fd',
    '500': '#5a48ef',
    '600': '#553ee3',
    '700': '#4d32d5',
    '800': '#4725c7',
    '900': '#3e00af',
  },
  rosa: {
    '50': '#f8ecec',
    '100': '#EECFCF', // Brand Rosa
    '200': '#da9c96',
    '300': '#ce766b',
    '400': '#d65a45',
    '500': '#d94e28',
    '600': '#cb4428',
    '700': '#ba3b23',
    '800': '#ad351c',
    '900': '#9f2b10',
  },
};
