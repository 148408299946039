import { radioTheme as baseRadioTheme } from 'themes/base/components/radio';
import { defineStyleConfig } from '@chakra-ui/react';

export const radioTheme = defineStyleConfig({
  baseStyle: {
    ...baseRadioTheme.baseStyle,

    control: {
      _checked: {
        borderRadius: 8,
        w: 8,
        h: 8,
        bgColor: 'darkGrey.700',
        borderColor: 'darkGrey.700',
        _before: {
          content: `""`,
          display: 'inline-block',
          pos: 'relative',
          w: '50%',
          h: '50%',
          borderRadius: '50%',
          bg: 'green.200',
        },
      },
    },
  },
  sizes: {
    md: {
      control: { w: 8, h: 8, borderRadius: 8 },
    },
  },
  defaultProps: {
    colorScheme: 'gray',
  },
});
