import { defineStyleConfig } from '@chakra-ui/react';

export const poiIconTheme = defineStyleConfig({
  baseStyle: {
    color: 'green.200',
  },
  variants: {
    outlined: {
      color: 'darkGrey.700',
      '& g': {
        stroke: 'green.200',
      },
    },
  },
});
