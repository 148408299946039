import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container', 'header', 'headline', 'button', 'minusIcon', 'text']);

export const faqItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'darkGrey.700',
      position: 'relative',
      borderBottom: '1px solid #4E5356',
      mt: 4,
      _first: {
        mt: 0,
      },
    },
    header: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'space-between',
      mb: 3,
      gap: { base: '20px', lg: '2rem' },
    },
    headline: {
      fontSize: [20, 28],
    },
    button: {
      w: [8, 10],
      h: [8, 10],
      minW: [8, 10],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      borderRadius: '50%',
      svg: {
        strokeWidth: 1,
      },
    },
    minusIcon: {
      bgColor: 'transparent',
      color: 'darkGrey.700',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    text: {
      fontSize: [16, 18],
      lineHeight: ['22px', '24px'],
      //animation from framer.motion instead
      // '& .sc-rich-text': {
      //   maxHeight: 0,
      //   opacity: 0,
      //   transition: 'max-height 0.6s ease-in-out, opacity 0.4s ease-in',
      // },
      // '&.openFaqItem': {
      //   '& .sc-rich-text': {
      //     opacity: 1,
      //     maxHeight: '500px',
      //   },
      // },
    },
  },
});
