import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'closeButton',
  'loadingIcon',
  'hostelMarker',
  'POIMarker',
  'markerText',
  'POIImage',
  'POITitle',
  'POIDescription',
  'POILink',
]);

export const mapsTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      height: '42rem',
      width: { base: '100%', lg: '42rem' },
      maxWidth: { base: '100%', lg: 'calc(100vw - 4rem)' },
      maxHeight: '125vw',
      borderRadius: { base: '20px', lg: '40px' },
      margin: { base: '1rem', lg: '2rem' },
      overflow: 'hidden',
      '& > div': {
        borderRadius: { base: '20px', lg: '40px' },
      },
      '& div div .gm-style': {
        borderRadius: { base: '20px', lg: '40px' },
        overflow: 'hidden',
      },
    },
    closeButton: {
      position: 'absolute',
      zIndex: 1,
      top: '-1rem',
      right: '-1rem',
      height: '2.188rem',
      width: '2.188rem',
      backgroundColor: '#212121',
      color: 'white',
      borderRadius: 'full',
      marginTop: '0.3rem',
      marginRight: '0.3rem',
      padding: '0',
    },
    loadingIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    hostelMarker: {
      height: '1.875rem',
      width: '1.875rem',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      backgroundColor: 'yellow.500',
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: '-2.15rem',
      left: '-0.95rem',
      boxShadow: 'md',
    },
    POIMarker: {
      height: '1.25rem',
      width: '1.25rem',
      backgroundColor: 'yellow.500',
      borderColor: 'yellow.500',
      borderWidth: '2px',
      borderRadius: 'full',
      borderBottomRightRadius: 0,
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: '-1.4rem',
      left: '-0.625rem',
      boxShadow: 'md',
      opacity: 0.75,
    },
    markerText: {
      position: 'absolute',
      top: '0.35rem',
      left: '50%',
      transform: 'translate(-50%, 0)',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontSize: '12px',
      color: 'gray.600',
      fontWeight: 'bold',
    },
    POIImage: {
      position: 'relative',
      width: '100%',
      height: '6.25rem',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      overflow: 'hidden',
    },
    POITitle: { fontSize: '18px', fontFamily: 'body', marginTop: '1rem', padding: '0 0.5rem' },
    POIDescription: {
      fontSize: '12px',
      fontFamily: 'body',
      marginTop: '0.5rem',
      marginBottom: '0.75rem',
      padding: '0 0.5rem',
    },
    POILink: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.5rem',
      marginBottom: '0.5rem',
    },
    POILinkDescription: {
      fontSize: '12px',
      fontFamily: 'body',
      fontWeight: 'bold',
      marginLeft: '0.5rem',
    },
  },
});

// Map themes styles, according to reference snazzymaps.com/style/227185/golden
// Except water color (changed to #B5C9C5)
export const mapThemeStyles = [
  // *** remove google maps poi icons and labels
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  // ***
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b47b0e',
      },
      {
        visibility: 'on',
      },
      {
        weight: '0.01',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#fafafa',
      },
      {
        visibility: 'on',
      },
      {
        gamma: '0.46',
      },
      {
        weight: '1.42',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e8e8e8',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ebe2d0',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        weight: '0.01',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'all',
    stylers: [
      {
        color: '#e6e6e6',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#edeceb',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        weight: '0.01',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#e1ddcc',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bc913e',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        weight: '1.86',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
      {
        color: '#dfd1b6',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#dfd1b6',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#ae925a',
      },
      {
        visibility: 'on',
      },
      {
        saturation: '-13',
      },
      {
        weight: '0.01',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b78e3f',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        weight: '3.93',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
      {
        weight: '0.01',
      },
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#d1b3b3',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b0956d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        weight: '4.91',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'all',
    stylers: [
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#b5c9c5',
      },
      {
        visibility: 'on',
      },
    ],
  },
];
