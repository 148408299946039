/**
 * Default breakpoints for all themes.
 *
 * @url https://tailwindcss.com/docs/responsive-design
 */
export const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};
