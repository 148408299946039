import { base } from './base';
import { nexthouse } from './nexthouse';
import { steelhouse } from './steelhouse';

type Themes = {
  themes: Record<string, Theme>;
  default: string;
};

type Theme = {
  name: string;
  config: typeof base;
};

type StoryBookThemeList = {
  value: string;
  title: string;
};

const themeConfigs: Themes = {
  themes: {
    steelhouse: {
      name: 'Steel House Copenhagen',
      config: steelhouse,
    },

    nexthouse: {
      name: 'Next House Copenhagen',
      config: nexthouse,
    },

    base: {
      name: 'Base Theme',
      config: base,
    },
  },

  default: 'base',
};

export function getTheme(themeKey: string | undefined): Theme {
  if (themeKey === undefined) {
    throw new Error(`Could not find theme ${themeKey}`);
  }

  return themeConfigs.themes[themeKey];
}

export function getStorybookThemesList(): Array<StoryBookThemeList> {
  return Object.keys(themeConfigs.themes).map((i) => {
    return {
      value: i,
      title: themeConfigs.themes[i].name,
    };
  });
}

export function getDefaultThemeId(): string {
  return themeConfigs.default;
}

export function getDefaultTheme(): Theme {
  return themeConfigs.themes[themeConfigs.default];
}
