import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'title',
  'imageContainer',
  'iconContainer',
  'icon',
  'bottomOverlay',
]);

export const slider1ItemTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      display: 'flex',
      color: 'white',
      height: { base: '13.125rem', md: '16.25rem' },
      width: { base: '8.75rem', md: '12rem' },
      maxWidth: '90%',
      marginRight: { base: '1rem', md: '2rem' },
      borderRadius: '20px',
      borderTopLeftRadius: 0,
    },
    title: {
      position: 'absolute',
      bottom: '1rem',
      left: '1rem',
      color: 'white',
      zIndex: 2,
      pointerEvents: 'none',
    },
    imageContainer: {
      flexGrow: 1,
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '20px',
      borderTopLeftRadius: 0,
      '.zoom-image': {
        transition: 'all 0.5s ease-in-out 0s',
        _hover: { transform: 'scale(1.05)' },
      },
      height: '100%',
      cursor: 'pointer',
    },
    iconContainer: {
      height: '3.75rem',
      width: '3.75rem',
      position: 'absolute',
      top: '-1.8rem',
      left: '1rem',
      zIndex: 1,
      backgroundColor: 'white',
      borderRadius: 'full',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none',
    },
    icon: {
      height: '2rem',
      width: '2rem',
      color: 'black',
    },
    bottomOverlay: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '30%',
      zIndex: 1,
      borderRadius: '20px',
      background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7))',
      pointerEvents: 'none',
    },
  },
});
