import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['container']);

export const videoComponentTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      // padding: '52.73% 0 0 0',
      position: 'relative',
      height: '100%',
      video: {
        objectFit: 'cover',
        height: '100%',
      },
      img: {
        objectFit: 'cover',
        height: '100%',
      },
    },
  },
});
