import { defineStyleConfig } from '@chakra-ui/react';
import { slideUp } from '../../../src/assets/animations';

/**
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
 */
export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontSize: 'md',
    fontWeight: 'semibold',
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'green.500',
      color: 'green.500',
      overflow: 'hidden',

      _hover: {
        bg: 'transparent',
        borderColor: 'green.600',
        color: 'green.600',

        span: {
          animation: `${slideUp} 1s ease`,
        },
      },
    },
    solid: {
      bg: 'green.500',
      color: 'black',
      overflow: 'hidden',

      _hover: {
        bg: 'green.600',

        span: {
          animation: `${slideUp} 1s ease`,
        },
      },
    },
    bookingStarter: {
      bg: 'yellow.500',
      color: 'black',
      overflow: 'hidden',
    },
  },
  sizes: {
    xs: {
      fontWeight: 'normal',
      fontSize: 'sm',
      py: 4,
      px: 5,
      borderRadius: '50px 20px 0px 50px',
    },
    sm: {
      padding: 5,
      borderRadius: '50px 20px 0px 50px',
    },
    lg: {
      py: 5,
      px: 36,
      borderRadius: '0px 50px 50px 20px',
    },
    wide: {
      py: 5,
      h: 12,
      width: '100%',
      borderRadius: '0px 50px 50px 20px',
    },
  },
});
