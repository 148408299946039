import { defineStyleConfig } from '@chakra-ui/react';

export const checkboxTheme = defineStyleConfig({
  baseStyle: {
    control: {
      w: 8,
      h: 8,
      border: '1px solid',
      borderColor: 'gray.400',
      borderRadius: 8,
      _checked: {
        bg: 'darkGrey.700',
        borderColor: 'darkGrey.700',

        _hover: {
          bg: 'darkGrey.800',
          borderColor: 'darkGrey.800',
        },

        _disabled: {
          borderColor: 'darkGrey.300',
          bg: 'darkGrey.300',
          color: 'darkGrey.700',
        },
      },
      _focusVisible: {
        boxShadow: 'none',
      },
      _indeterminate: {
        bg: 'gray.500',
        borderColor: 'gray.500',
        color: 'white',
      },
      _disabled: {
        bg: 'gray.100',
        borderColor: 'gray.100',
      },
    },
    icon: {
      color: 'green.200',
    },
  },
  variants: {
    'custom-control': () => ({
      container: {
        alignItems: 'flex-start',
        '.chakra-checkbox__label': {
          lineHeight: 1,
          fontSize: '26px',
          marginTop: '4px',
          maxWidth: '90%',
        },
        '.chakra-checkbox__label p': {
          marginTop: '1rem',
          fontSize: '14px',
        },
      },
    }),
    'dorm-control': () => ({
      container: {
        alignItems: 'flex-start',
        '.chakra-checkbox__label': {
          marginTop: '8px',
          lineHeight: 1,
          whiteSpace: 'nowrap',
        },
        '.chakra-checkbox__label p': {
          fontSize: '14px',
          fontStyle: 'italic',
        },
      },
    }),
    'dorm-control-private': () => ({
      container: {
        alignItems: 'flex-start',
        '.chakra-checkbox__label': {
          marginTop: '8px',
          lineHeight: 1,
        },
        '.chakra-checkbox__label p': {
          fontSize: '14px',
          fontStyle: 'italic',
        },
      },
    }),
    'cart-control': () => ({
      control: {
        borderColor: 'gray.600',
      },
    }),
  },
});
