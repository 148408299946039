import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'closeButton',
  'counter',
  'contentContainer',
  'mainCarousel',
  'verticalCarousel',
  'arrowPrev',
  'arrowNext',
  'arrowPrevVertical',
  'arrowNextVertical',
  'verticalCarouselImage',
]);

export const productCardGalleryTheme = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
      padding: { base: '1rem 0 0 0', lg: '1rem' },
      borderRadius: '20px',
      backgroundColor: '#212121',
      color: 'white',
      overflow: 'hidden',
      WebkitTapHighlightColor: 'transparent',
    },
    closeButton: {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      borderWidth: '1px',
      borderRadius: 'full',
      opacity: '0.5',
    },
    counter: {
      height: { base: '3rem', lg: '4.5rem' },
      fontSize: { base: '18px', lg: '28px' },
      fontFamily: 'body',
    },
    contentContainer: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      mt: '2rem',
    },
    mainCarousel: {
      position: 'relative',
      width: { base: '100%', lg: 'calc(100% - 15rem)' },
      '.carousel-root': {
        height: '100% !important',
      },
      '.carousel-slider': {
        height: '100% !important',
      },
      '.slider-wrapper': {
        height: '100% !important',
      },
      '.slider': {
        height: '100% !important',
        '.slider': {
          //verticalCarouselImage 9.5 + 1rem marginBottom
          height: '10.5rem !important',
        },
      },
    },
    verticalCarousel: {
      position: 'relative',
      width: { base: 0, lg: '15rem' },
      paddingLeft: { base: 0, lg: '10px' },
      '.carousel-wrapper': {
        height: '100% !important',
      },
      '.carousel': {
        height: '100% !important',
        position: 'absolute',
        top: '0',
        zIndex: 20,
      },
      '.carousel-slider': {
        zIndex: 30,
      },
      '.carousel-root': {
        height: '100% !important',
        position: 'relative',
      },
      '.slider-wrapper': {
        height: '100% !important',
      },
    },
    modalBigCarouselContainer: {
      height: '100%',
      width: '100%',
    },
    arrowPrev: {
      position: 'absolute',
      top: '50%',
      left: '5%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      zIndex: '2',
    },
    arrowNext: {
      position: 'absolute',
      top: '50%',
      right: '5%',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
    },
    arrowPrevVertical: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      borderRadius: 'full',
      bg: 'rgba(0,0,0,0.5)',
      zIndex: '31',
      width: '33px',
      height: '33px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    arrowNextVertical: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      borderRadius: 'full',
      bg: 'rgba(0,0,0,0.5)',
      zIndex: '50',
      width: '33px',
      height: '33px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    verticalCarouselImage: {
      position: 'relative',
      width: '100%',
      height: '9.5rem',
      overflow: 'hidden',
      cursor: 'pointer',
      img: {
        borderRadius: '10px',
      },
    },
    verticalCarouselImageActive: {
      border: '1px solid #ecff18',
    },
    modalHeader: {
      alignItems: 'center',
      width: '50%',
      display: 'flex',
    },
    exitButtonContainer: {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      display: 'flex',
      width: '55px',
      height: '55px',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    arrowNextModalBigContainer: {
      position: 'absolute',
      top: '50%',
      right: '20px',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
    },
    arrowPrevModalBigContainer: {
      position: 'absolute',
      top: '50%',
      left: '20px',
      borderRadius: '50%',
      bg: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
      zIndex: '2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '55px',
      height: '55px',
    },
    modalBigImageContainer: {
      img: {
        borderRadius: { base: '20px', md: '40px', lg: '10px' },
      },
    },
    portraitImageContainer: {
      // aspectRatio: '1/1',
      position: 'relative',
      width: '100%',
      // margin: '0 auto',
      minHeight: 'auto',
      maxWidth: '100%',
      overflow: 'hidden',
      marginBottom: '12px',
    },
  },
});
